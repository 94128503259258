import { ReportTypes } from "@src/types/report.types";
import { pipe } from "fp-ts/lib/function";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Backend } from "../../backend/Backend";
import { FullReportPage } from "../../components/report/full-report";
import { O, RD } from "../../prelude";

export const ReportPage: React.FC<{}> = ({}) => {
  const params = useParams<{ saId: string }>();
  const saId = params.saId!;

  const [rdReport, setRdReport] = React.useState<
    RD.RemoteData<any, ReportTypes.SliReport>
  >(RD.initial);

  useEffect(() => {
    console.log("SA ID: ", saId);

    Backend.get<any>(`self-assessment/${saId}/report`).then((r) => {
      console.log("GOT REPORT: ", r);
      setRdReport(RD.success(r));
    });
  }, [saId]);

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <></>,
      (report) => (
        <div className="w-screen h-screen flex flex-col items-center">
          <Report report={report} />
        </div>
      )
    )
  );
};

const Report: React.FC<{ report: ReportTypes.SliReport }> = ({ report }) => {
  // const [screenshot, setScreenshot] = React.useState<string | null>(null)
  return (
    <div
      id="report"
      className="flex flex-col grow-0 shrink"
      style={{ flexBasis: "800px" }}
    >
      <button
        id="print-button"
        className="w-24 h-12 bg-blue-500 text-white"
        onClick={() => {
          printPDF().then();
          // takeScreenShot('page-2').then(img => {
          //   setScreenshot(img)
          // })
        }}
      >
        Print
      </button>
      {/* {screenshot && (
        <div className="border-4 border-red-400">

          <img src={screenshot} alt="screenshot" />
        </div>
      )} */}
      <FullReportPage report={report} />
    </div>
  );
};

async function takeScreenShot(elId: string) {
  const domElement = document.getElementById(elId)!;
  const canvas = await html2canvas(domElement, {});

  const img = canvas.toDataURL("image/png");

  return img;
}

async function printPDF() {
  const firstPageImg = await takeScreenShot("page-1");
  const secondPageImg = await takeScreenShot("page-2");
  const thirdPageImg = await takeScreenShot("page-3");
  const fourthPageImg = await takeScreenShot("page-4");

  const pdf = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: "a4",
  });
  pdf.addImage(firstPageImg, "JPEG", 0, 0, 8, 11.5);

  pdf.addPage();
  pdf.addImage(secondPageImg, "JPEG", 0, 0, 8, 11.5);

  pdf.addPage();
  pdf.addImage(thirdPageImg, "JPEG", 0, 0, 8, 11.5);

  pdf.addPage();
  pdf.addImage(fourthPageImg, "JPEG", 0, 0, 8, 11.5);

  pdf.save("report.pdf");
}

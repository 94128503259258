import * as React from "react";
import { friendlyNameToHexColor, scoreToFriendlyName } from "../report.utils";

export const OverallLeadershipScoreView: React.FC<{ score: string }> = ({
  score,
}) => {
  const scoreWithOneDecimal = parseFloat(score).toFixed(1);
  return (
    <div className="flex flex-col shrink">
      <h3 className="font-bold">Overall Leadership Score</h3>
      <div className="flex flex-row">
        <div
          className="p-24"
          style={{
            backgroundColor: friendlyNameToHexColor(
              scoreToFriendlyName(parseInt(score))
            ),
          }}
        >
          <h1 className="text-3xl font-bold ">{scoreWithOneDecimal}</h1>
        </div>
        <div className="ml-8">
          <h4 className="font-bold text-lg">Scale</h4>
          <ScaleItem score={5} />
          <ScaleItem score={4} />
          <ScaleItem score={3} />
          <ScaleItem score={2} />
          <ScaleItem score={1} />
        </div>
      </div>
    </div>
  );
};

const ScaleItem: React.FC<{ score: number }> = ({ score }) => {
  return (
    <div
      className="font-bold"
      style={{
        color: friendlyNameToHexColor(scoreToFriendlyName(score)),
      }}
    >
      {`${score} - ${scoreToFriendlyName(score)}`}
    </div>
  );
};

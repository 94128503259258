import React from "react";
import { PieChart } from "react-minimal-pie-chart";

export type PieChartData = {
  title: string;
  value: number;
  color: string;
};

export type SaturnPieChartProps = {
  data: PieChartData[];
  withoutFont?: boolean;
};

export const SaturnPieChart: React.FC<SaturnPieChartProps> = ({
  data,
  withoutFont = false,
}) => {
  return (
    <>
      <div style={{ width: "100%", height: "100%" }} className="pie-chart">
        <PieChart
          data={data}
          lineWidth={100}
          label={({ dataEntry }) =>
            dataEntry.value === data[0].value ? (
              <text
                dominantBaseline="central"
                x="50"
                y="50"
                dx="0"
                dy="0"
                textAnchor="middle"
                style={{
                  fontFamily: withoutFont ? "Arial" : "inter",
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#222222",
                }}
              >{`${Math.round(dataEntry.percentage)}%`}</text>
            ) : (
              `${Math.round(dataEntry.percentage)}%`
            )
          }
          labelPosition={85}
          startAngle={90}
          labelStyle={() => {
            return {
              fontFamily: withoutFont ? "Arial" : "inter",
              fontSize: "8px",
              fontWeight: "bold",
              color: "#222222",
            };
          }}
          segmentsStyle={{ strokeWidth: "14" }}
        />
      </div>
    </>
  );
};

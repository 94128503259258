import { LStorage } from "@/src/lstorage";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const PeerOnboardIndexPage: React.FC<Record<string, never>> = () => {
  const url = useLocation();
  const queryParams = new URLSearchParams(url.search);
  const tokenParam = queryParams.get("jwt");
  const [myToken, setMyToken] = useState<string | null>(null);
  const nav = useNavigate();
  useEffect(() => {
    console.log("TOKEN PARAM! ", tokenParam);
    if (tokenParam) {
      setMyToken(tokenParam);
      LStorage.setJwt(tokenParam);
      setTimeout(() => {
        nav("/peer-onboard/preliminary-questions");
      }, 100);
    }
  }, [tokenParam]);

  if (!tokenParam) {
    return <div>You are not authorized to view this page</div>;
  }

  if (!myToken) {
    return <div>Loading...</div>;
  }

  return <div></div>;
};

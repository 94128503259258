import { Backend } from "@/src/backend/Backend";
import { BackButton, PrimaryButton } from "@/src/components/buttons";
import { SurveyContainer } from "@/src/components/survey/survey.components";
import React, { useEffect, useState } from "react";
import {
  GetSelfAssessmentResponse,
  QuestionWithAnswer,
} from "./self-assessment.page";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "@/src/loading";
import TrashIcon from "@/public/images/icons/trash-icon.png";

export type GetTopImportantQuestionsResponse = {
  question_id: string;
  sa_session_id: string;
  sort_order: number;
};

export const LevelOfWeaknessesPage: React.FC = () => {
  const [assessmentWeakQuestions, setAssessmentWeakQuestions] = useState<
    QuestionWithAnswer[]
  >([]);

  const nav = useNavigate();
  const [pickedQuestions, setPickedQuestions] = useState<QuestionWithAnswer[]>(
    []
  );

  const pickQuestion = (questionId: string) => {
    if (pickedQuestions.length < 5)
      setPickedQuestions((beforePicked) => [
        ...beforePicked,
        assessmentWeakQuestions.filter(
          (question) => question.id === questionId
        )[0],
      ]);
  };

  const unpickQuestion = (questionId: string) => {
    setAssessmentWeakQuestions((currentQuestions) => [
      ...currentQuestions,
      pickedQuestions.filter(
        (pickedQuestion) => pickedQuestion.id === questionId
      )[0],
    ]);
    setPickedQuestions((currentPickedQuestions) =>
      currentPickedQuestions.filter(
        (pickedQuestion) => pickedQuestion.id !== questionId
      )
    );
  };

  const changeLevel = (
    questionId: string,
    targetQuestion: QuestionWithAnswer
  ) => {
    const draggingItem = pickedQuestions.filter(
      (question) => question.id === questionId
    )[0];
    const currentIndex = pickedQuestions.indexOf(draggingItem);
    const targetIndex = pickedQuestions.indexOf(targetQuestion);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const updatedItems = [...pickedQuestions];
      updatedItems.splice(currentIndex, 1);
      updatedItems.splice(targetIndex, 0, draggingItem);
      setPickedQuestions(updatedItems);
    }
  };

  const pickAnswerLevel = (QuestionsWithAnswers: GetSelfAssessmentResponse) => {
    const weakAndImproveQuestions = QuestionsWithAnswers.qas.filter(
      (qa) => qa.answer !== null && qa.answer < 3
    );
    const FunctionalQuestions = QuestionsWithAnswers.qas.filter(
      (qa) => qa.answer !== null && qa.answer === 3
    );
    const GoodQuestions = QuestionsWithAnswers.qas.filter(
      (qa) => qa.answer !== null && qa.answer === 4
    );
    const StrengthQuestions = QuestionsWithAnswers.qas.filter(
      (qa) => qa.answer !== null && qa.answer === 5
    );

    if (weakAndImproveQuestions.length > 2) {
      setAssessmentWeakQuestions(weakAndImproveQuestions);
    } else if (FunctionalQuestions.length > 2) {
      setAssessmentWeakQuestions(
        FunctionalQuestions.concat(weakAndImproveQuestions)
      );
    } else if (GoodQuestions.length > 2) {
      setAssessmentWeakQuestions(
        GoodQuestions.concat(FunctionalQuestions).concat(
          weakAndImproveQuestions
        )
      );
    } else if (StrengthQuestions.length > 2) {
      setAssessmentWeakQuestions(
        StrengthQuestions.concat(GoodQuestions)
          .concat(FunctionalQuestions)
          .concat(weakAndImproveQuestions)
      );
    }
  };

  useEffect(() => {
    pickedQuestions.forEach((pickedQuestion) => {
      if (assessmentWeakQuestions.includes(pickedQuestion))
        setAssessmentWeakQuestions((currentAssessmentWeakQuestions) =>
          currentAssessmentWeakQuestions.filter(
            (question) => question.id !== pickedQuestion.id
          )
        );
    });
  }, [pickedQuestions]);

  useEffect(() => {
    Backend.get<GetSelfAssessmentResponse>(`/self-assessment`, "self-onboard")
      .then((r) => {
        Backend.get<GetTopImportantQuestionsResponse[]>(
          `/top-important-questions`,
          "self-onboard"
        )
          .then((r2) => {
            const newPickedQuestions = r.qas.filter((qa) =>
              r2.some((r2Unit) => r2Unit.question_id === qa.id)
            );
            setPickedQuestions(newPickedQuestions);
            pickAnswerLevel(r);
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <BackButton navToBack={"/self-onboard/self-assessment-by-pillar/7"} />
        </div>
        <div className="flex flex-col gap-10 self-center items-center w-full pt-2 bg-[#FAF6F0]">
          <div className="text-center">
            <h1
              className="m-4 text-2xl font-normal"
              style={{ fontFamily: "Arial" }}
            >
              LEVEL OF WEAKNESSES
            </h1>
            <div className="flex flex-col mx-10">
              <SurveyContainer explanation='In the "Questions to Pick" section you have your answers of major weaknesses, pick from 3 to 5 the ones you consider most important for improvement and order them from highest to lowest.'>
                <h6 className="font-inter text-xl p-4">Picked Questions</h6>
                <OrderList
                  questions={pickedQuestions}
                  deleted={unpickQuestion}
                  changeOrder={changeLevel}
                />

                <h6 className="font-inter text-xl p-4">Questions to Pick</h6>
                {assessmentWeakQuestions !== null && (
                  <PickUpSection
                    pickQuestion={pickQuestion}
                    questions={assessmentWeakQuestions}
                  ></PickUpSection>
                )}
              </SurveyContainer>
              <div className="py-8">
                <PrimaryButton
                  onClick={() => {
                    const body = pickedQuestions.map((question, index) => {
                      return { questionId: question.id, sortOrder: index + 1 };
                    });
                    Backend.post<any>(
                      `/top-important-questions/submission`,
                      body,
                      "self-onboard"
                    ).then(() => {
                      nav(`/self-onboard/final-questions`);
                    });
                  }}
                  disabled={pickedQuestions.length < 3}
                  style={{
                    backgroundColor: "#222222",
                    color: "#FAF7F2",
                    padding: "16px 20px 16px 20px",
                    opacity: pickedQuestions.length >= 3 ? "" : "30%",
                  }}
                >
                  <div className="flex items-center">
                    Next Section
                    <HiChevronRight />
                  </div>
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PickUpSection: React.FC<{
  questions: QuestionWithAnswer[];
  pickQuestion: (questionId: string) => void;
}> = ({ questions, pickQuestion }) => {
  const answerName = (answerNumber: number) => {
    switch (answerNumber) {
      case 1:
        return "Weakness";
      case 2:
        return "Needs Improvement";
      case 3:
        return "Functional";
      case 4:
        return "Good";
      case 5:
        return "Strength";
    }
  };
  return (
    <>
      <div className="border rounded p-4">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th>Pillar</th>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, idx) => (
              <tr
                className="border-t hover:bg-[#F4F0E5] cursor-pointer"
                key={idx}
                onClick={() => pickQuestion(question.id)}
              >
                <td>{question.pillar_name}</td>
                <td className=" p-4">{question.question}</td>

                <td className=" p-4">
                  {question.answer ? answerName(question.answer) : "No answer"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {questions.length === 0 && (
          <div className="flex justify-center">
            <LoadingSpinner dim={45} />
          </div>
        )}
      </div>
    </>
  );
};

const OrderList: React.FC<{
  questions: QuestionWithAnswer[];
  deleted: (questionId: string) => void;
  changeOrder: (questionId: string, targetQuestion: QuestionWithAnswer) => void;
}> = ({ questions, deleted, changeOrder }) => {
  const answerName = (answerNumber: number) => {
    switch (answerNumber) {
      case 1:
        return "Weakness";
      case 2:
        return "Needs Improvement";
      case 3:
        return "Functional";
      case 4:
        return "Good";
      case 5:
        return "Strength";
    }
  };

  const [draggingItem, setDraggingItem] = useState<QuestionWithAnswer | null>(
    null
  );

  const handleDragStart = (e: React.DragEvent, item: QuestionWithAnswer) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent, targetItem: QuestionWithAnswer) => {
    console.log(e);
    if (!draggingItem) return;
    changeOrder(draggingItem.id, targetItem);
  };
  return (
    <div className="flex items-center border rounded justify-center p-4">
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="w-[5%]">Level</th>
            <th className="w-[10%]">Pillar</th>
            <th className="w-[50%]">Question</th>
            <th className="w-[20%]">Answer</th>
            <th className="w-[10%]"></th>
          </tr>
        </thead>
        <tbody>
          {questions.length > 0 &&
            questions.map((question, index) => (
              <tr
                key={question.id}
                className={`item dragDrop border-t hover:bg-[#F4F0E5] `}
                draggable="true"
                onDragStart={(e) => handleDragStart(e, question)}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, question)}
              >
                <td className="border-r">{index + 1}</td>
                <td className=" p-4">{question.pillar_name}</td>
                <td className=" p-4">{question.question}</td>

                <td className=" p-4">
                  {question.answer ? answerName(question.answer) : "No answer"}
                </td>
                <td
                  className="p-4 cursor-pointer"
                  onClick={() => deleted(question.id)}
                >
                  <div className="flex w-[30%]">
                    <img src={TrashIcon} alt="trash-icon" />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

import { FullReportPdf } from "@/src/components/report/full-report-pdf";
import { OverallLeadershipSurveyForm } from "@/src/types/index.types";
import { PDFViewer } from "@react-pdf/renderer";
import { Backend } from "@src/backend/Backend";
import { FullReportPage } from "@src/components/report/full-report";
import { O, RD } from "@src/prelude";
import { ReportTypes, SliReport } from "@src/types/report.types";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

type ReportSummaryResponse = {
  report: SliReport;
  overallLeadershipSurvey: OverallLeadershipSurveyForm;
};

export const AdminPUserReportPage: React.FC = () => {
  const params = useParams<{ userId: string }>();

  /* Check if the url contains the query param displayType, and if that displayType = 'pdf' */
  const [searchParams] = useSearchParams();
  const displayAsHtml = searchParams.get("displayType") === "html";

  const userId = params.userId!;

  const [rdReport, setRdReport] = React.useState<
    RD.RemoteData<any, ReportTypes.SliReport>
  >(RD.initial);

  useEffect(() => {
    Backend.getAdmin<ReportSummaryResponse>(
      `users/${userId}/report-summary`
    ).then((r) => {
      setRdReport(RD.success(r.report));
    });
  }, [userId]);

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <></>,
      (report) =>
        !displayAsHtml ? (
          <div className="w-screen h-screen relative">
            <PDFViewer
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <FullReportPdf report={report} />
            </PDFViewer>
            <Link
              to={`/admin/users/${userId}/report?displayType=html`}
              className="absolute bottom-8 right-12 z-20 bg-blue-400 px-4 py-1 rounded-3xl"
            >
              View as html
            </Link>
          </div>
        ) : (
          <div className="w-screen h-screen flex flex-col items-center">
            <Report report={report} />
          </div>
        )
    )
  );
};

const Report: React.FC<{ report: ReportTypes.SliReport }> = ({ report }) => {
  // const [screenshot, setScreenshot] = React.useState<string | null>(null)
  return (
    <div
      id="report"
      className="flex flex-col grow-0 shrink"
      style={{ flexBasis: "800px" }}
    >
      <FullReportPage report={report} />
    </div>
  );
};

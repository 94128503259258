import { Link, Outlet } from "react-router-dom";

export const AdminDashboardLayout: React.FC<{}> = ({}) => {
  return (
    <div className="w-screen h-screen flex">
      <ul className="w-64 bg-slate-200 flex flex-col px-4 py-16 gap-16 list-none">
        <li className="text-xl font-medium text-slate-600">
          <Link to="/admin/dashboard/user-reports">User Reports</Link>
        </li>
        <li className="text-xl font-medium text-slate-600">
          <Link to="/admin/dashboard/user-answers">User Answers</Link>
        </li>
        <li className="text-xl font-medium text-slate-600">
          <Link to="/admin/dashboard/survey-questions">Survey Questions</Link>
        </li>
        <li className="text-xl font-medium text-slate-600">
          <Link to="/admin/dashboard/peer-assessments">Peer Assessments</Link>
        </li>
      </ul>
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};

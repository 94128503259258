import { Backend } from "@/src/backend/Backend";
import { FullPeerReportPdf } from "@/src/components/report/full-peer-report-pdf";
import { FullReportPdf } from "@/src/components/report/full-report-pdf";
import { O, RD } from "@/src/prelude";
import { ReportTypes } from "@/src/types/report.types";
import { PDFViewer } from "@react-pdf/renderer";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ReportPdfPage: React.FC = () => {
  const params = useParams<{
    reportType: "peer-assessment" | "self-assessment";
    sessionId: string;
  }>();
  const [rdReport, setRdReport] = React.useState<
    RD.RemoteData<any, ReportTypes.SliReport>
  >(RD.initial);
  const [mockReport, setMockReport] = React.useState();

  useEffect(() => {
    if (params.reportType === "self-assessment") {
      Backend.get<any>(`/sessions/${params.sessionId}/report`, "my").then(
        (res) => {
          setRdReport(RD.success(res));
        }
      );
    } else {
      Backend.get<any>(`sample-sli-360`).then((res) => {
        setMockReport(res.fake360Report);
        Backend.get<any>(`/sessions/${params.sessionId}/report`, "my").then(
          (res) => {
            setRdReport(RD.success(res));
          }
        );
      });
    }
  }, []);

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (report) => (
        <PDFViewer
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {params.reportType === "self-assessment" ? (
            <FullReportPdf report={report} />
          ) : (
            <FullPeerReportPdf report={report} mockReport={mockReport} />
          )}
        </PDFViewer>
      )
    )
  );
};

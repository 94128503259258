import { Sli360ReportTypes } from "@/src/types/360report.types";
import {
  averageScores,
  negativeScorePercentage,
  positiveScorePercentage,
  selfAvgDifference,
  votes,
} from "@/src/utils/scoresColors";

export const TotalAverageChart: React.FC<{
  pillarSummaryPage: Sli360ReportTypes.PillarSumarryPage;
}> = ({ pillarSummaryPage }) => {
  const diferentNamesFromSpecificPeopleFunction = () => {
    const names: string[] = [];

    pillarSummaryPage.forEach((p) => {
      p.avgRatingsFromSpecificPeople.forEach((sp) => {
        if (!names.includes(sp.personName)) {
          names.push(sp.personName);
        }
      });
    });
    return names;
  };

  const scales = [
    { number: 5, title: "Strong", color: "#457733" },
    { number: 4, title: "Good", color: "#9BCA86" },
    { number: 3, title: "Functional", color: "#D4D4D4" },
    { number: 2, title: "Needs Improvement", color: "#FF7F23" },
    { number: 1, title: "Major Weakness", color: "#E64925" },
  ];

  const diferentNamesFromSpecificPeople: string[] =
    diferentNamesFromSpecificPeopleFunction();

  const styleBorderRounded = (index: number) => ({
    borderTopRightRadius: index === 0 ? "5px" : "0",
    borderTopLeftRadius: index === 0 ? "5px" : "0",
    borderBottomLeftRadius:
      index === pillarSummaryPage.length - 1 ? "5px" : "0",
    borderBottomRightRadius:
      index === pillarSummaryPage.length - 1 ? "5px" : "0",
  });

  const combinateStylesObjects = (
    object1: React.CSSProperties,
    object2: React.CSSProperties
  ) => {
    return { ...object1, ...object2 };
  };

  return (
    <div className="flex flex-col p-8">
      <table className="table-fixed ">
        <thead>
          <tr className="h-[160px] text-[13px] font-inter ">
            <th className="w-[350px]">
              <div className="h-[160px] ">
                <div className="flex items-center justify-center py-[12px] h-[50%] items-center my-1 mx-[2px]"></div>
                <div className="h-[50%] justify-between grid grid-cols-1">
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[13px] py-[12px] border border-[#B1B1B1] rounded-md items-center m-1">
                    Leadership Pillars
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[190px]">
              <div className="h-[160px] mx-1 w-[192px]">
                <div className="flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md h-[50%] items-center my-1 mx-[2px] ">
                  Overall Strengths and Weaknesses
                </div>
                <div className="h-[50%] justify-between grid grid-cols-3">
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center my-1 mx-[2px] w-[60px]">
                    <div>Strength</div>
                    <div className="flex mt-1  pb-1 w-full justify-center self-center items-center bg-[#9BCA86] rounded">
                      (4s & 5s)
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center  my-1 mx-[2px] w-[60px]">
                    <div>Functional</div>
                    <div className="flex pb-1 items-center w-full justify-center  self-center  mt-1 bg-[#9C9C9C] rounded">
                      (3s)
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center  my-1 mx-[2px] w-[60px]">
                    <div>Weakness</div>
                    <div className="flex pb-1 items-center w-full justify-center self-center mt-1 bg-[#F9A77D] rounded">
                      (1s & 2s)
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[130px]">
              <div className="h-[160px] mx-1 w-[126px]">
                <div className=" flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md h-[50%] my-1 mx-[2px]">
                  Weakensses % Each Group
                </div>
                <div className="h-[50%] align-content-around justify-between grid grid-cols-2">
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center col-span-1   my-1 mx-[2px] w-[60px]">
                    Execs
                  </div>
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center col-span-1  my-1 mx-[2px] w-[60px]">
                    Direct’s Direct
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[190px]">
              <div className="h-[160px] mx-1 w-[192px]">
                <div className="flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md h-[50%] my-1 mx-[2px]">
                  Self Avg Rating
                </div>
                <div className="h-[50%] justify-between grid grid-cols-3">
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center  my-1 mx-[2px]  w-[60px]">
                    Self Rating
                  </div>
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center  my-1 mx-[2px]  w-[60px]">
                    Average Rating
                  </div>
                  <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center  my-1 mx-[2px]  w-[60px]">
                    Self-Average Difference
                  </div>
                </div>
              </div>
            </th>
            <th style={{ width: 64 * diferentNamesFromSpecificPeople.length }}>
              <div
                className={`h-[160px] mx-1 `}
                style={{ width: 64 * diferentNamesFromSpecificPeople.length }}
              >
                <div className="flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md h-[50%] my-1 mx-[2px]">
                  Avg Ratings From Specific People
                </div>
                <div
                  className={`h-[50%] justify-between  grid grid-cols-${diferentNamesFromSpecificPeople.length}`}
                >
                  {diferentNamesFromSpecificPeople.map((name, index) => (
                    <div
                      key={name + index}
                      className="flex items-center justify-center bg-[#F4F0E5] text-[11px] py-[12px] border border-[#B1B1B1] rounded-md items-center w-[60px]  my-1 mx-[2px]"
                    >
                      {name}
                    </div>
                  ))}
                </div>
                <div className="h-[50%]"></div>
              </div>
            </th>
            <th className="w-[130px]">
              <div className="h-[160px] mx-1 w-[126px]">
                <div className="flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md h-[50%] my-1 mx-[2px]">
                  Votes for Biggest Improvement Opportunities
                </div>
                <div className="h-[50%] align-content-around justify-between grid grid-cols-2 text-[11px]">
                  <div className="flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md items-center col-span-1   my-1 mx-[2px] w-[60px]">
                    Votes within each Pillar
                  </div>
                  <div className="flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md items-center col-span-1   my-1 mx-[2px] w-[60px]">
                    Final Votes as Most Important
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {pillarSummaryPage.map((p, index) => {
            return (
              <tr
                className="font-inter text-[12px]"
                key={p.ratings[0].attribute + index}
              >
                <td
                  className={`min-w-[300px] border border-[#B1B1B1] h-[102px] grid grid-cols-5 mx-1 p-0 mt-${
                    index === 0 ? "2" : "0"
                  }`}
                  style={styleBorderRounded(index)}
                >
                  <div className="bg-[#FAF6F0]  px-[10px]  py-[6px] justify-center flex items-center font-inter col-span-1">{`${p.pillar.number}.0`}</div>
                  <div className="bg-[#FDFCFA]  px-[10px] py-[6px] border-l  border-[#B1B1B1] flex items-center col-span-4">{`${p.pillar.description}`}</div>
                </td>
                <td
                  className={`mt-${index === 0 ? "2" : "0"}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div className="grid grid-cols-3 h-[100px] font-inter font-semibold text-[20px] w-[192px] ml-1">
                    <div
                      className="flex items-center justify-center border border-[#FAFAFA] items-center mx-[2px] w-[60px]"
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: positiveScorePercentage(
                          p.overallPercentageOfStrengthsAndWeaknessess.strength
                        ),
                      })}
                    >
                      {p.overallPercentageOfStrengthsAndWeaknessess.strength}%
                    </div>
                    <div
                      className="flex items-center justify-center bg-[#D4D4D4]  border border-[#FAFAFA]  items-center mx-[2px] w-[60px]"
                      style={styleBorderRounded(index)}
                    >
                      {p.overallPercentageOfStrengthsAndWeaknessess.functional}%
                    </div>
                    <div
                      className="flex items-center justify-center border border-[#FAFAFA] items-center  mx-[2px] w-[60px]"
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: negativeScorePercentage(
                          p.overallPercentageOfStrengthsAndWeaknessess.weakness
                        ),
                      })}
                    >
                      {p.overallPercentageOfStrengthsAndWeaknessess.weakness}%
                    </div>
                  </div>
                </td>
                <td
                  className={`mt-${index === 0 ? "2" : "0"}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div className="grid grid-cols-2 h-[100px] font-inter font-semibold text-[20px] w-[126px] ml-1">
                    <div
                      className={`flex items-center justify-center  border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: negativeScorePercentage(
                          p.weaknessesPctEachGroup !== undefined
                            ? p.weaknessesPctEachGroup.find(
                                (value) => value.group === "Exec"
                              )?.pct
                            : 0
                        ),
                      })}
                    >
                      {p.weaknessesPctEachGroup &&
                        p.weaknessesPctEachGroup.find(
                          (value) => value.group === "Exec"
                        )?.pct}
                      %
                    </div>
                    <div
                      className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: negativeScorePercentage(
                          p.weaknessesPctEachGroup !== undefined
                            ? p.weaknessesPctEachGroup.find(
                                (value) => value.group === "Direct"
                              )?.pct
                            : 0
                        ),
                      })}
                    >
                      {p.weaknessesPctEachGroup &&
                        p.weaknessesPctEachGroup.find(
                          (value) => value.group === "Direct"
                        )?.pct}
                      %
                    </div>
                  </div>
                </td>
                <td
                  className={`mt-${index === 0 ? "2" : "0"}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div className="grid grid-cols-3 h-[100px] font-inter font-semibold text-[20px] w-[192px] ml-1">
                    <div
                      className={`flex items-center justify-center   border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: averageScores(
                          p.selfAvgRating.selfRating
                        ),
                      })}
                    >
                      {p.selfAvgRating.selfRating}
                    </div>
                    <div
                      className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: averageScores(
                          p.selfAvgRating.avgRating
                        ),
                      })}
                    >
                      {p.selfAvgRating.avgRating}
                    </div>
                    <div
                      className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: selfAvgDifference(
                          p.selfAvgRating.selfAvgDifference
                        ),
                      })}
                    >
                      {p.selfAvgRating.selfAvgDifference}
                    </div>
                  </div>
                </td>
                <td
                  className={`mt-${index === 0 ? "2" : "0"}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div
                    className={`grid grid-cols-${p.avgRatingsFromSpecificPeople.length} ml-1 h-[100px] font-inter font-semibold text-[20px]`}
                    style={{
                      width: `${64 * diferentNamesFromSpecificPeople.length}px`,
                    }}
                  >
                    {p.avgRatingsFromSpecificPeople.map((person, index) => (
                      <div
                        key={person.personName + index}
                        className={`flex items-center justify-center bg-[$}]   border border-[#FAFAFA] items-center mx-[2px] w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: averageScores(person.rating),
                          }
                        )}
                      >
                        {person.rating}
                      </div>
                    ))}
                  </div>
                </td>
                <td
                  className={`mt-${index === 0 ? "2" : "0"}`}
                  style={{ verticalAlign: "bottom" }}
                >
                  <div className="grid grid-cols-2 h-[100px] font-inter font-semibold text-[20px] ml-1 w-[126px]">
                    <div
                      className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: votes(
                          p.votesForBiggestImprovementOpportunity
                            .votesWithinEachPillar
                        ),
                      })}
                    >
                      {
                        p.votesForBiggestImprovementOpportunity
                          .votesWithinEachPillar
                      }
                    </div>
                    <div
                      className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                      style={combinateStylesObjects(styleBorderRounded(index), {
                        backgroundColor: votes(
                          p.votesForBiggestImprovementOpportunity
                            .finalVotesAsMostImportant
                        ),
                      })}
                    >
                      {
                        p.votesForBiggestImprovementOpportunity
                          .finalVotesAsMostImportant
                      }
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          alignSelf: "self-end",
        }}
        className="flex justify-center items-center gap-4 "
      >
        {scales.map((scale, index) => (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
            }}
            key={index}
          >
            <div
              style={{
                backgroundColor: scale.color,
                width: "12px",
                height: "12px",
              }}
            ></div>
            <div
              style={{
                height: "26px",
              }}
            >
              <div
                style={{
                  fontFamily: "inter",
                  fontSize: "12px",
                  color: "#222222",
                  paddingLeft: "2px",
                }}
              >
                {scale.number} - {scale.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

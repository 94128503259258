import { Font, Image, Text, View } from "@react-pdf/renderer";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import gilroyBold from "@src/fonts/Gilroy-Bold.ttf";
import gilroy from "@src/fonts/Gilroy-Regular.ttf";
import gilroySemiBold from "@src/fonts/Gilroy-SemiBold.ttf";
import React, { useEffect, useState } from "react";
import { SaturnBarChart } from "./opportunities-saturn-bar-chart";
import { ReactToImage } from "@/src/utils/ReactToImage";
Font.register({
  family: "Gilroy",
  src: gilroy,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Semi-Bold",
  src: gilroySemiBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Bold",
  src: gilroyBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

export type SaturnBarChartPdfProps = {
  title: string | null;
  data:
    | {
        majorWakness: number;
        needsImprovement: number;
        functional: number;
        good: number;
        strong: number;
      }[];
  labels: string[];
  width?: string;
  height?: string;
};

export const SaturnBarChartPdf: React.FC<SaturnBarChartPdfProps> = ({
  title,
  data,
  labels,
  width,
  height,
}) => {
  const [image, setImageData] = useState<any>();
  useEffect(() => {
    ReactToImage(<SaturnBarChart labels={labels} data={data} />, setImageData);
  }, []);
  if (!image) {
    return <></>;
  } else
    return (
      <View
        style={{
          width: width ? width : "480px",
          height: height ? height : "480px",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            backgroundColor: "white",
            fontFamily: "Inter",
            fontWeight: "bold",
            fontSize: "12px",
            color: "#222222",
          }}
        >
          {title}
        </Text>
        {image && <Image source={image} />}
      </View>
    );
};

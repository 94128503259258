import React, { useEffect } from "react";
import QuestionLabel from "./question-label";

type OptionsQuestionProps = {
  question: string;
  options: string[];
  answer: string;
  setAnswer: (newAnswer: string) => void;
};
const OptionQuestion: React.FC<OptionsQuestionProps> = ({
  question,
  options,
  answer,
  setAnswer,
}) => {
  useEffect(() => {
    console.log(answer);
  }, []);
  return (
    <div className="flex flex-col items-start gap-8 ">
      <QuestionLabel question={question} />
      <div className="flex flex-col self-start gap-6">
        {options.map((option, index) => (
          <div
            className="flex items-center gap-1 font-gilroy text-bold"
            key={index}
          >
            <label className="relative">
              <input
                type="radio"
                id={option}
                value={answer}
                className="border w-5 h-5 flex"
                style={{
                  appearance: "none",
                  borderRadius: "50%",
                  backgroundColor: answer === option ? "#CFBB8B" : "white",
                  border: answer === option ? "" : "#7D7D7D solid 1.5px",
                }}
                onClick={() => setAnswer(option)}
              />
              <span
                className={`absolute inset-0 flex items-center justify-center h-[20px]`}
              >
                {answer === option && (
                  <img
                    src="/images/icons/check.png"
                    alt="check-icon"
                    className="h-[8.78px] w-[12.55px] top-[7.64px]"
                  />
                )}
              </span>
            </label>

            <label
              htmlFor={option}
              className="font-gilroy font-semibold text-[18px] m-2 first-uppercase"
            >
              {option.replace("-", " ")}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptionQuestion;

import { matchRoutes, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { pipe } from "fp-ts/lib/function";
import { Arr, O } from "../prelude";

export function useCurrentAssumedMatchingRoute() {
  const location = useLocation();
  const routeMatches = matchRoutes(routes, location);

  console.log("ROUTE MATCH! ", routeMatches);

  const lastRoute = pipe(routeMatches!, Arr.last, O.toNullable)!;

  console.log("LAST ROUTE! ", lastRoute);

  return lastRoute;
}

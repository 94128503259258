import { NavIntro } from "@/src/components/navs/nav-intro";
import { menuSteps } from "@/src/types/enums.types";
import { useCurrentAssumedMatchingRoute } from "@/src/utils/useCurrentPath";
import { Outlet } from "react-router-dom";
import { ProgressBar } from "../anon/components";

export const PeerOnboardDashboardLayout: React.FC = () => {
  const curRoute = useCurrentAssumedMatchingRoute();
  return (
    <div className="w-full min-h-screen flex flex-col">
      <NavIntro />
      <ProgressBar progressPct={progressPctForPath(curRoute.pathname)} />
      <div className="w-screen items-stretch flex-1 flex">
        <LeftMenu selected={curRoute.pathname} />
        <div className="flex-1 flex flex-col items-center">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

function progressPctForPath(path: string) {
  switch (path) {
    case "/peer-onboard/preliminary-questions":
      return 10;
    case "/peer-onboard/overall-leadership-survey":
      return 20;
    case "/peer-onboard/top-pillar":
      return 25;
    case "/peer-onboard/peer-assessment-start":
      return 30;
    case "/peer-onboard/peer-assessment/1":
      return 35;
    case "/peer-onboard/peer-assessment/2":
      return 40;
    case "/peer-onboard/peer-assessment/3":
      return 45;
    case "/peer-onboard/peer-assessment/4":
      return 50;
    case "/peer-onboard/peer-assessment/5":
      return 60;
    case "/peer-onboard/peer-assessment/6":
      return 70;
    case "/peer-onboard/peer-assessment/7":
      return 85;
    default:
      return 0;
  }
}

const LeftMenuLink: React.FC<{ to: string; selected: string }> = ({
  to,
  selected,
}) => {
  const isSelected = selected === to;

  const isAssessmentSubMenu =
    selected.includes("/peer-onboard/peer-assessment") &&
    to === "/peer-onboard/peer-assessment-start";

  const titleForPath = () => {
    switch (to) {
      case "/peer-onboard/preliminary-questions":
        return menuSteps.preliminary;
      case "/peer-onboard/overall-leadership-survey":
        return "Overall Leadership Survey";
      case "/peer-onboard/top-pillar":
        return "Top Pillar";
      case "/peer-onboard/peer-assessment-start":
        return "Saturn Leadership Index Assessment";
      case "/peer-onboard/final-comments":
        return "Comments";
      default:
        return "Unknown";
    }
  };

  return (
    <div
      className={`${isAssessmentSubMenu ? "" : "mb-5"} font-bodonixt  text-xl ${
        isSelected || isAssessmentSubMenu ? "opacity-100" : "opacity-30"
      }`}
    >
      {titleForPath()}
    </div>
  );
};

const LeadershipBehaviorsSubMenuLink: React.FC<{
  to: string;
  selected: string;
}> = ({ to, selected }) => {
  const isSelected = selected === to;

  const titleForPath = () => {
    console.log("SELECTED: ", selected);
    switch (to) {
      case "/peer-onboard/peer-assessment/1":
        return "Inspire with a Clear Vision & Roadmap";
      case "/peer-onboard/peer-assessment/2":
        return "Listen with Openness & Curiosity";
      case "/peer-onboard/peer-assessment/3":
        return "Speak with Transparency & Authenticity";
      case "/peer-onboard/peer-assessment/4":
        return "Facilitate Healthy Debates & Meetings";
      case "/peer-onboard/peer-assessment/5":
        return "Make Decisions Inclusively & Effectively";
      case "/peer-onboard/peer-assessment/6":
        return "Delegate Effectively & Creates Accountable Teams";
      case "/peer-onboard/peer-assessment/7":
        return "Develop People & Teams";
      default:
        return "Unknown";
    }
  };

  return (
    <div
      className={`mb-2 font-gilroy ml-2 text-md text-[#7D7D7D] ${
        isSelected ? "opacity-100" : "opacity-30"
      }`}
    >
      {titleForPath()}
    </div>
  );
};

const LeftMenu: React.FC<{ selected: string }> = ({ selected }) => {
  return (
    <div className="items-stretch flex gap-1 w-[25%] bg-[#F4F0E5] ">
      <div className="pl-[12%] pr-[12%] pt-20 flex flex-col gap-2">
        <LeftMenuLink
          to="/peer-onboard/preliminary-questions"
          selected={selected}
        />
        <LeftMenuLink
          to="/peer-onboard/overall-leadership-survey"
          selected={selected}
        />
        <LeftMenuLink to="/peer-onboard/top-pillar" selected={selected} />
        <LeftMenuLink
          to="/peer-onboard/peer-assessment-start"
          selected={selected}
        />
        {selected.includes("/peer-onboard/peer-assessment/") && (
          <>
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/1"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/2"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/3"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/4"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/5"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/6"
              selected={selected}
            />
            <LeadershipBehaviorsSubMenuLink
              to="/peer-onboard/peer-assessment/7"
              selected={selected}
            />
          </>
        )}
        <LeftMenuLink to="/peer-onboard/final-comments" selected={selected} />
      </div>
    </div>
  );
};

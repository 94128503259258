type RadioButtonProps = {
  isSelected: boolean;
  name: string;
  value: string;
  onSelected: (value: string) => void;
  label?: string;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  isSelected,
  name,
  value,
  onSelected,
  label,
}) => {
  const id = `${name}-${value}`;
  return (
    <div className="flex items-center relative">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={isSelected}
        onChange={(evt) => {
          onSelected(evt.target.value);
        }}
        onClick={() => {
          isSelected === true ? onSelected("") : null;
        }}
        className="border w-5 h-5 "
        style={{
          appearance: "none",
          borderRadius: "50%",
          backgroundColor: isSelected ? "#CFBB8B" : "white",
          border: isSelected ? "#CFBB8B solid 1.5px" : "#7D7D7D solid 1.5px",
          position: "relative",
          cursor: "pointer",
        }}
      />
      {isSelected && (
        <span
          className={`absolute inset-0 flex items-center justify-center h-[20px]`}
          style={{ pointerEvents: "none" }}
        >
          <img
            src="/images/icons/check.png"
            alt="check-icon"
            className="h-[8.78px] w-[12.55px] top-[7.64px] "
          />
        </span>
      )}
      <label
        className="text-xl font-medium font-hind text-slate-600"
        htmlFor={id}
      >
        {label && label}
      </label>
    </div>
  );
};

import { Backend } from "@/src/backend/Backend";
import { RD } from "@/src/prelude";
import { useState } from "react";
import { useForm } from "react-hook-form";

type StartPeerAssessmentForm = {
  peerEmail: string;
  userEmail: string;
};

export const AdminPeerAssessmentsPage: React.FC<Record<string, never>> = () => {
  const { register, handleSubmit } = useForm<StartPeerAssessmentForm>();
  const [rdResult, setRdResult] = useState<RD.RemoteData<any, any>>(RD.initial);

  return (
    <div className="w-screen h-screen flex py-8 px-8">
      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setRdResult(RD.pending);
          Backend.postAdmin(
            `users/${data.userEmail}/start-peer-assessment`,
            data
          ).then((r) => {
            setRdResult(RD.success(r));
            console.log("RESULT OF START PEER ASSESSMENT! ", r);
          });
        })}
      >
        <label className="text-xl font-medium text-slate-600">
          Email of person to be evaluted (User)
        </label>
        <input
          className="border border-slate-300 rounded-md p-2"
          type="email"
          {...register("userEmail", { required: true })}
        />
        <label className="text-xl font-medium text-slate-600">
          Email of person evaluating (Peer)
        </label>
        <input
          className="border border-slate-300 rounded-md p-2"
          type="email"
          {...register("peerEmail", { required: true })}
        />
        <button
          className="bg-slate-600 text-white rounded-md p-2"
          type="submit"
        >
          Send Email to start peer assessment
        </button>
      </form>
      <div>{JSON.stringify(rdResult)}</div>
    </div>
  );
};

import {
  friendlyNameToHexColor,
  pillarRatingExplanation,
  pillarRatingIcon,
  scoreToFriendlyName,
  PillarAvgTotalRatings,
  PillarTypesPercentage,
  StrengthsOrWeaknessRatings,
} from "@/src/components/report/report.utils";
import { ReportTypes } from "@/src/types/report.types";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import bodoniXT from "@src/fonts/BodoniXT.ttf";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import hindBold from "@src/fonts/Hind-Bold.ttf";
import hindRegular from "@src/fonts/Hind-Regular.ttf";
import hindSemibold from "@src/fonts/Hind-SemiBold.ttf";
import React, { ReactElement, useEffect } from "react";
import SaturnLogo from "/images/saturn-leadership-logo.png";
import upLevelIcon from "/images/uplevel-icon.png";
import copyrightIcon from "/images/copyright-icon.png";
import tmIcon from "/images/trademark-icon.png";
import { SaturnBarChartPdf } from "../charts/saturn-bar-chart/opportunities-saturn-bar-chart-pdf.js";
import ScoreOnReportPdf from "../charts/score-on-report/score-on-report-pdf.js";
import { SaturnPieChartPdf } from "../charts/saturn-pie-chart/saturn-pie-chart-pdf.js";
import { TotalAverageChartPdf } from "../charts/totals-averages-chart/total-average-chart-pdf.js";
import { Sli360ReportTypes } from "@/src/types/360report.types.js";
import { AveragesVotesChartPdf } from "../charts/averages-votes-chart/averages-votes-chart-pdf.js";
import StaticPillarChart from "@/public/images/static-pillar-chart.jpg";
import VotesExplanationStatic from "@/public/images/votes-explanation-static.jpg";
import { CommentsChartPdf } from "../charts/comments-chart/comments-chart-pdf.js";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});
Font.register({
  family: "Hind",
  src: hindRegular,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Hind-Bold",
  src: hindBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Hind",
  src: hindSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "BodoniXT",
  src: bodoniXT,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const formatDate = (dateStr: string): string => {
  const inputDate = new Date(dateStr);
  const day = inputDate.getDate();
  const month = inputDate.toLocaleString("en-US", { month: "long" });
  const year = inputDate.getFullYear();
  const suffix =
    (day >= 11 && day <= 13) || ![1, 2, 3].includes(day % 10)
      ? "th"
      : ["st", "nd", "rd"][(day % 10) - 1];

  return `${month} ${day}${suffix}, ${year}`;
};

export const FullPeerReportPdf: React.FC<{
  report: ReportTypes.SliReport;
  mockReport?: Sli360ReportTypes.FullReport;
}> = ({ report, mockReport }) => {
  useEffect(() => {
    console.log("REPORT: ", report);
    console.log("MOCK REPORT: ", mockReport?.commentsPage);
  }, []);
  return (
    <Document>
      <Page size="A3" style={styles.page}>
        <View
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Image
            src={SaturnLogo}
            style={{
              width: "42%",
            }}
          />
          <Text
            style={{
              backgroundColor: "white",
              paddingTop: "30px",
              fontFamily: "Inter",
              fontWeight: "normal",
              fontSize: "30px",
              color: "#222222",
            }}
          >
            Peer-Assessment Report
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text
              style={{
                backgroundColor: "white",
                fontFamily: "Inter",
                fontWeight: "normal",
                fontSize: "12px",
                color: "#222222",
              }}
            >
              SATURN LEADERSHIP
            </Text>
            <Image
              src={tmIcon}
              style={{
                width: "5.5px",
                height: "6.5px",
                paddingTop: "2px",
              }}
            />
            <Text
              style={{
                backgroundColor: "white",
                fontFamily: "Inter",
                fontWeight: "normal",
                fontSize: "12px",
                color: "#222222",
              }}
            >
              {" INDEX (SLI)"}
            </Text>
            <Image
              src={tmIcon}
              style={{
                width: "5.5px",
                height: "6.5px",
                paddingTop: "2px",
              }}
            />
          </View>

          <Text
            style={{
              backgroundColor: "white",
              fontFamily: "Inter",
              fontWeight: "semibold",
              fontSize: "10px",
              color: "#7D7D7D",
            }}
          >
            {report !== undefined &&
              formatDate(report.date.sessionSubmited.toString()).toUpperCase()}
          </Text>
        </View>
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={1}
            pageName={"Saturn LEADERSHIP INDEX".toUpperCase()}
            pageSubName="LEADERSHIP ASSESSMENT"
          />
          <View
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Image
              src={StaticPillarChart}
              style={{
                width: "700px",
                padding: "10px",
              }}
            />
          </View>
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={2}
            pageName={"Leadership Summary".toUpperCase()}
            pageSubName="Ratings for Past 6 Months"
          />
          <StatisticsSection report={report} />
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={3}
            pageName={"SLI Key & Explanation of Votes".toUpperCase()}
            pageSubName=""
          />
          <View
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              src={VotesExplanationStatic}
              style={{
                width: "800px",
                padding: "10px",
              }}
            />
          </View>
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={4}
            pageName={"Saturn LEADERSHIP INDEX".toUpperCase()}
            pageSubName="Overall Totals & Averages"
          />
          {mockReport && (
            <TotalAverageChartPdf
              data={mockReport.pillarSummaryPage}
              height="800px"
              width="800px"
            />
          )}
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={5}
            pageName={"Strengths & Improvement Opportunities".toUpperCase()}
            pageSubName="Based on Averages & Votes"
          />
          {mockReport && (
            <AveragesVotesChartPdf
              data={mockReport.pillarSummaryPage}
              height="950px"
              width="950px"
            />
          )}
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            height: "1000px",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={6}
            pageName={"General Comments".toUpperCase()}
            pageSubName={"on Your Strengths & Improvement Opportunities".toUpperCase()}
          />
          {mockReport?.commentsPage && (
            <CommentsChartPdf data={mockReport?.commentsPage} />
          )}
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={7}
            componentPageName={<SaturnLeadershipIndexTitle />}
            pageSubName="To check out how you can up-level a leadership practice."
          />
          {report.sa.pillarBreakdown.slice(0, 2).map((pb, idx) => (
            <SaTablePillarSection
              key={pb.pillarName}
              idx={idx + 1}
              pillarBreakdown={pb}
            />
          ))}
        </View>
        <PageFooter />
      </Page>

      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={8}
            componentPageName={<SaturnLeadershipIndexTitle />}
            pageSubName="To check out how you can up-level a leadership practice."
          />
          {report.sa.pillarBreakdown.slice(2, 4).map((pb, idx) => (
            <SaTablePillarSection
              key={pb.pillarName}
              idx={idx + 3}
              pillarBreakdown={pb}
            />
          ))}
        </View>
        <PageFooter />
      </Page>
      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={9}
            componentPageName={<SaturnLeadershipIndexTitle />}
            pageSubName="To check out how you can up-level a leadership practice."
          />
          {report.sa.pillarBreakdown.slice(4, 6).map((pb, idx) => (
            <SaTablePillarSection
              key={pb.pillarName}
              idx={idx + 5}
              pillarBreakdown={pb}
            />
          ))}
        </View>
        <PageFooter />
      </Page>
      <Page size="A3" style={styles.page}>
        <View
          style={{
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <PageHeader
            pageNumber={10}
            componentPageName={<SaturnLeadershipIndexTitle />}
            pageSubName="To check out how you can up-level a leadership practice."
          />
          <SaTablePillarSection
            key={report.sa.pillarBreakdown[6].pillarName}
            idx={7}
            pillarBreakdown={report.sa.pillarBreakdown[6]}
          />
        </View>
        <PageFooter />
      </Page>
    </Document>
  );
};

const PageHeader: React.FC<{
  pageNumber: number;
  pageName?: string;
  componentPageName?: ReactElement;
  pageSubName?: string;
}> = ({ pageNumber, pageName, pageSubName, componentPageName }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{ flex: 1 }}>
        <Image
          src={SaturnLogo}
          style={{
            width: "100px",
          }}
        />
      </View>
      <View
        style={{
          flex: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {pageName && (
          <Text
            style={{
              fontFamily: "Inter",
              fontWeight: "bold",
              color: "#222222",
              fontSize: "12px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {pageName}
          </Text>
        )}
        <>{componentPageName}</>
        <Text
          style={{
            fontFamily: "Inter",
            fontWeight: "semibold",
            marginTop: "2px",
            color: "#7D7D7D",
            fontSize: "8px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {pageSubName?.toUpperCase()}
        </Text>
      </View>
      <View style={{ flex: 1, textAlign: "right" }}>
        <Text
          style={{ fontFamily: "Inter", fontSize: "6px", color: "#222222" }}
          render={({ totalPages }) => `Page ${pageNumber} of ${totalPages - 1}`}
        />
      </View>
    </View>
  );
};

const PageFooter: React.FC = () => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "30px 20px 30px 20px",
        position: "absolute",
        zIndex: -1,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      fixed
    >
      <View style={{ display: "flex", flexDirection: "row" }}>
        <Image
          src={copyrightIcon}
          style={{ width: "8.5px", height: "8.5px", padding: "1px" }}
        />
        <Text
          style={{
            fontFamily: "Inter",
            fontSize: "7.5px",
            color: "#222222",
          }}
        >
          Jason Gore
        </Text>
      </View>

      <Text
        style={{
          fontFamily: "Inter",
          fontSize: "7.5px",
          color: "#222222",
        }}
      >
        Saturn Leadership.org
      </Text>
    </View>
  );
};

const StatisticsSection: React.FC<{
  report: ReportTypes.SliReport;
}> = ({ report }) => {
  const pillarBreakdown = report.sa.pillarBreakdown;
  const pillarRatingsAvg = PillarAvgTotalRatings(report.sa.pillarBreakdown);
  const percentageRatingTypes = PillarTypesPercentage(
    report.sa.leadershipSummary
  );
  return (
    <View
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "90%",
          marginTop: "50px",
        }}
      >
        <View
          style={{
            borderColor: "#E1E1E1",
            borderWidth: 1,
            width: "49%",
            padding: "10px 30px 10px 30px",
          }}
        >
          <ScoreOnReportPdf
            title="Overall Leadership Score"
            score={pillarRatingsAvg}
          />
        </View>
        <View
          style={{
            borderColor: "#E1E1E1",
            borderWidth: 1,
            width: "49%",
            padding: "10px 30px 10px 30px",
          }}
        >
          <SaturnPieChartPdf
            title={"Response Summary"}
            data={[
              {
                color: "#83BC75",
                title: "Strengths",
                value: percentageRatingTypes[0],
              },
              {
                color: "#FEB165",
                title: "Functional",
                value: percentageRatingTypes[1],
              },
              {
                color: "#E64925",
                title: "Weaknesses",
                value: percentageRatingTypes[2],
              },
            ]}
            responsesTypeAmounts={[
              { type: "Execs", amount: 5 },
              { type: "Family", amount: 1 },
              { type: "Direct's Direct", amount: 6 },
            ]}
            width="90%"
          />
        </View>
      </View>
      <View
        style={{
          marginTop: "30px",
          borderColor: "#E1E1E1",
          borderWidth: 1,
          padding: "10px",
          maxHeight: "400px",
          maxWidth: "800px",
        }}
      >
        <SaturnBarChartPdf
          title={"Strengths & Improvement Opportunities".toUpperCase()}
          labels={pillarBreakdown.map((pillar) => {
            return pillar.pillarNumber + " " + pillar.pillarDesc;
          })}
          width="700px"
          data={StrengthsOrWeaknessRatings(report)}
        />
      </View>
    </View>
  );
};

const SaTablePillarSection: React.FC<{
  idx: number;
  pillarBreakdown: ReportTypes.Sa.PillarBreakdown;
}> = ({ pillarBreakdown }) => {
  return (
    <View
      style={{
        flexDirection: "column",
        gap: "2px",
        width: "90%",
        alignSelf: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "#FCEFCC",
              padding: "0px 8px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#222222",
                  fontFamily: "Inter",
                }}
              >
                {`${pillarBreakdown.pillarNumber}. ${pillarBreakdown.pillarDesc}`}
              </Text>
            </View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "10px",
                color: "#222222",
                fontFamily: "Inter",
                alignItems: "flex-end",
              }}
            >
              {`Average Rating:`}
            </Text>
          </View>
          <View style={{ width: "4px", height: "100%" }} />
          <View
            style={{
              backgroundColor: ratingBackgroundColor(
                parseInt(pillarBreakdown.avg)
              ),
              padding: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "14px",
                fontFamily: "Inter",
                fontWeight: "bold",
              }}
            >{`${pillarBreakdown.avg}`}</Text>
          </View>
        </View>
      </View>
      <View
        style={{
          backgroundColor: "#F4F0E5",
          padding: "4px 20px",
          fontFamily: "Hind",
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <Image
          src={pillarRatingIcon(parseInt(pillarBreakdown.avg))}
          style={{ width: "12px", height: "12px", padding: "1px" }}
        />
        <Text
          style={{
            fontFamily: "Inter",
            color: "#222222",
            fontWeight: "bold",
            fontSize: "10px",
            lineHeight: "150%",
          }}
        >
          {pillarRatingExplanation(parseInt(pillarBreakdown.avg))}
        </Text>
      </View>
      <View style={{ flexDirection: "column", gap: "2px" }}>
        {pillarBreakdown.ratings.map((rating) => {
          const backgroundColor = friendlyNameToHexColor(
            scoreToFriendlyName(rating.rating)
          );
          return (
            <View
              key={rating.attribute}
              style={{
                flexDirection: "row",
                backgroundColor: "#FAF6F0",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  flex: 1,
                  padding: "1px 20px",
                  fontFamily: "Inter",
                  fontWeight: "semibold",
                  fontSize: "10px",
                  color: "#222222",
                }}
              >
                {rating.attribute}
              </Text>
              <Text
                style={{
                  padding: "5px 15px",
                  backgroundColor,
                  fontSize: "8px",
                  fontFamily: "Inter",
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {rating.rating}
              </Text>
            </View>
          );
        })}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            backgroundColor: "#F4F0E5",
            padding: "8px 20px",
          }}
        >
          <Image
            src={upLevelIcon}
            style={{
              width: "13px",
              height: "13px",
            }}
          />
          <Link
            src={`#SliLibraryLink`}
            style={{
              color: "#222222",
              fontSize: "10px",
              fontFamily: "Inter",
              fontWeight: "semibold",
            }}
          >
            Visit our SLI Library for this Pillar
          </Link>
        </View>
      </View>
    </View>
  );
};

const SaturnLeadershipIndexTitle: React.FC = () => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "2px",
      }}
    >
      <Text
        style={{
          fontFamily: "Inter",
          fontWeight: "bold",
          color: "#222222",
          fontSize: "10px",
        }}
      >
        {"Saturn Leadership Index".toUpperCase()}
      </Text>
      <Image
        src={tmIcon}
        style={{
          width: "4.5px",
          height: "5.5px",
          paddingTop: "1px",
          marginRight: "3px",
        }}
      />
      <Text
        style={{
          fontFamily: "Inter",
          fontWeight: "bold",
          color: "#222222",
          fontSize: "10px",
        }}
      >
        {" (SLI) Self-Assessment Results".toUpperCase()}
      </Text>
    </View>
  );
};

function ratingBackgroundColor(rating: number): string {
  return friendlyNameToHexColor(scoreToFriendlyName(rating));
}

import { useNavigate } from "react-router-dom";
import { PrimaryButtonNext } from "../../components/buttons";

import { NavIntro } from "@/src/components/navs/nav-intro";
import { useForm } from "react-hook-form";

const EXPLANATION_COPY_1 = `The Saturn Leadership™ Index Self Assessment will help you identify your strengths and development opportunities. At the end, you will receive a report that you can print out.`;
const EXPLANATION_COPY_2 = `Make sure you have at least 10 minutes to complete all at once.`;

export const OnboardPage: React.FC = () => {
  const { handleSubmit } = useForm<{ email: string }>();
  const nav = useNavigate();

  const onSubmit = () => {
    nav("/welcome/lets-get-started");
  };

  return (
    <>
      <NavIntro />
      <div
        className=" relative flex justify-center h-screen "
        style={{
          backgroundImage: `url("/images/two-people-talking.jpg")`,
          backgroundSize: "cover",
          minHeight: "443px",
        }}
      >
        <div className="h-full flex flex-col justify-center items-center text-[#FFFFFF]  z-[99]">
          <div className="flex flex-col items-center max-w-3xl gap-3">
            <h6 className="font-gilroy">LET’S GET STARTED</h6>
            <h5 className=" text-5xl font-bodonixt mb-8 text-center">
              Identify your leadership blindspots
            </h5>
            <span className="text-center font-gilroy">
              <p>{EXPLANATION_COPY_1}</p>
              <p>{EXPLANATION_COPY_2}</p>
            </span>
            <form
              className="flex flex-col items-center gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mt-5">
                <PrimaryButtonNext
                  type="submit"
                  title="Begin the Self Assessment"
                />
              </div>
            </form>

            <div
              className="font-gilroy text-sm cursor-pointer"
              onClick={() => {
                nav("/welcome/login");
              }}
            >
              Already a user? Login instead
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

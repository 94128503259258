import html2canvas from "html2canvas";
import { ReactElement } from "react";
import { createRoot } from "react-dom/client";

export const ReactToImage = async (
  component: ReactElement,
  returnFunction: (imagenString: string) => void,
  editWidth?: string,
  editHeight?: string
) => {
  const container = document.createElement("div");

  document.body.appendChild(container);
  container.style.position = "fixed";
  container.style.left = "-9999px";
  container.style.top = "-9999px";
  editWidth ? (container.style.width = editWidth) : container.style.width;
  editHeight ? (container.style.height = editHeight) : container.style.height;

  const root = createRoot(container);
  root.render(component);
  setTimeout(() => {
    try {
      html2canvas(container, { allowTaint: true }).then((canvas) => {
        canvas.getContext("2d", { willReadFrequently: true });
        const imageBase64 = canvas.toDataURL("image/jpeg", 0.5);
        document.body.removeChild(container);
        returnFunction(imageBase64);
      });
    } catch (error) {
      console.error("Error: ", error);
      document.body.removeChild(container);
    }
  }, 800);
};

import { Backend } from "@/src/backend/Backend"
import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { SliReport } from "@/src/types/report.types"
import { FullReportPage } from "@/src/components/report/full-report"
import { O, RD } from "@src/prelude"
import { pipe } from "fp-ts/lib/function"
import { OverallLeadershipSurveyForm } from "@/src/types/index.types"


type ReportSummaryResponse = {
  report: SliReport
  overallLeadershipSurvey: OverallLeadershipSurveyForm
}

export const AdminPUserReportSummaryPage: React.FC<{}> = ({ }) => {
  const [rdReport, setRdReport] = React.useState<RD.RemoteData<any, ReportSummaryResponse>>(RD.initial)
  const params = useParams<{ userId: string }>()

  const userId = params.userId!

  useEffect(() => {
    Backend.getAdmin<ReportSummaryResponse>(`users/${userId}/report-summary`).then(r => {
      setRdReport(RD.success(r))
      console.log("R! ", r)
    })
  }, [userId])

  return (
    <div className="flex flex-col gap-8 p-12">
      <h1 className="text-3xl font-medium">User Report Summary</h1>
      {pipe(
        rdReport,
        RD.toOption,
        O.fold(
          () => (<div>Loading...</div>),
          ({ report, overallLeadershipSurvey }) => (
            <div className="flex flex-col gap-24">
              <OverallLeadershipSurveyView survey={overallLeadershipSurvey} />
              <Link to={`/admin/users/${userId}/report`}>
                <button className="bg-slate-800 rounded-2xl py-4 px-8 font-bold text-xl text-white">
                  View Report in Full
                </button>
              </Link>
              <FullReportPage report={report} />
            </div>

          )
        )
      )}
    </div>
  )
}

const OverallLeadershipSurveyView: React.FC<{ survey: OverallLeadershipSurveyForm }> = ({ survey }) => {
  return (
    <div className="flex flex-col items-baseline gap-4 bg-white p-8 rounded-2xl">
      <h1 className="font-bold text-2xl">Self-Assessed Overall Leadership Survey</h1>
      <div className="bg-slate-200 flex flex-col rounded-2xl p-4">
        <h3 className="font-bold text-xl">Overall Leadership</h3>
        <div className="text-2xl">{survey.overallLeadership}</div>
      </div>
      <table className="">
        <thead>
          <tr>
            <th className="text-left border">Pillar</th>
            <th className="text-left border">Score</th>
          </tr>
        </thead>
        <tbody>
          {survey.overallPillarScores.map((ps, idx) => (
            <tr key={idx}>
              <td className="pr-8 py-4 border">{ps.pillar.description}</td>
              <td className="border text-center">{ps.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
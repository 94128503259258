import * as Sentry from "@sentry/react";

export module ErrorTracking {
  export function captureException<E>(e: E) {
    console.log("ERRORR! ", e);
    Sentry.captureException(e);
  }

  export function captureError(message: string) {
    Sentry.captureMessage(message, "error");
  }

  export function captureResponseError<E = unknown>(p: {
    status: number;
    body: E;
  }) {
    const message = `Response error: ${p.status} ${JSON.stringify(p.body)}`;
    Sentry.captureMessage(message, "error");
  }

  export function initSentry() {
    Sentry.init({
      dsn: "https://f5fd492d0440cf8aa3a77cc53bd8a048@o4506399818973184.ingest.sentry.io/4506399821070336",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

import { Backend } from "@/src/backend/Backend";
import { PrimaryButton } from "@/src/components/buttons";
import OptionQuestion from "@/src/components/questions/options-question";
import TextQuestion from "@/src/components/questions/text-question.page";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { RD } from "@/src/prelude";
import { menuSteps } from "@/src/types/enums.types";
import { RoleToAssessee } from "@/src/types/index.types";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export type PeerPreliminaryQuestionsForm = {
  firstName: string;
  lastName: string;
  roleToAssessee: RoleToAssessee;
  roleInCompany: string;
};

export const PeerPreliminaryQuestionsPage: React.FC<
  Record<string, never>
> = () => {
  const nav = useNavigate();
  const [rdPrelimQas, setRdPrelimQas] = React.useState<
    RD.RemoteData<any, PeerPreliminaryQuestionsForm>
  >(RD.initial);
  const [formComplete, setFormComplete] = React.useState<boolean>(false);

  useEffect(() => {
    setRdPrelimQas(RD.pending);
    Backend.get<PeerPreliminaryQuestionsForm>(
      `/preliminary-qas`,
      "peer-onboard"
    ).then((res) => {
      console.log(res);
      setRdPrelimQas(RD.success(res));
    });
  }, []);

  useEffect(() => {
    if (RD.isSuccess(rdPrelimQas))
      setFormComplete(
        rdPrelimQas.value.firstName !== undefined &&
          rdPrelimQas.value.lastName !== undefined &&
          rdPrelimQas.value.roleInCompany !== undefined &&
          rdPrelimQas.value.roleToAssessee !== undefined &&
          rdPrelimQas.value.firstName !== null &&
          rdPrelimQas.value.firstName?.trim() !== "" &&
          rdPrelimQas.value.lastName !== null &&
          rdPrelimQas.value.lastName?.trim() !== "" &&
          rdPrelimQas.value.roleInCompany !== null &&
          rdPrelimQas.value.roleInCompany?.trim() !== "" &&
          rdPrelimQas.value.roleToAssessee !== null
      );
  }, [rdPrelimQas]);

  const onSubmit = (data: PeerPreliminaryQuestionsForm) => {
    Backend.post("/preliminary-qas", data, "peer-onboard").then((r) => {
      console.log(r);
      nav("/peer-onboard/overall-leadership-survey");
    });
  };

  return pipe(
    rdPrelimQas,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      (e) => <div>{JSON.stringify(e)}</div>,
      (completeQuestions) => (
        <div className="p-12 flex flex-col w-[80%]">
          <h1 className="self-center text-4xl font-bodonixt text-black m-14 text-center">
            {menuSteps.preliminary}
          </h1>
          <div className="flex flex-col">
            <div className="flex w-full">
              <div className="w-[50%] mr-5">
                <TextQuestion
                  question={"First Name"}
                  answer={completeQuestions.firstName}
                  setAnswer={(newAnswer: string) => {
                    {
                      const updatedRdPrelimQas = completeQuestions;
                      updatedRdPrelimQas.firstName = newAnswer;
                      setRdPrelimQas(RD.success(updatedRdPrelimQas));
                    }
                  }}
                />
              </div>

              <div className="w-[50%] mb-[60px]">
                <TextQuestion
                  question={"Last Name"}
                  answer={completeQuestions.lastName}
                  setAnswer={(newAnswer: string) => {
                    {
                      const updatedRdPrelimQas = completeQuestions;
                      updatedRdPrelimQas.lastName = newAnswer;
                      setRdPrelimQas(RD.success(updatedRdPrelimQas));
                    }
                  }}
                />
              </div>
            </div>
            <div className="mb-[60px]">
              <OptionQuestion
                options={["manager", "peer", "direct-report"]}
                question={"Role to User"}
                answer={completeQuestions.roleToAssessee as string}
                setAnswer={(newAnswer: string) => {
                  const updatedRdQuestions = completeQuestions;
                  if (
                    newAnswer === "manager" ||
                    newAnswer === "peer" ||
                    newAnswer === "direct-report"
                  ) {
                    updatedRdQuestions.roleToAssessee = newAnswer;
                  }
                  setRdPrelimQas(RD.success(updatedRdQuestions));
                }}
              />
            </div>
            <div className="mb-[60px]">
              <TextQuestion
                question={"Role in Company"}
                answer={completeQuestions.roleInCompany}
                setAnswer={(newAnswer: string) => {
                  {
                    const updatedRdPrelimQas = completeQuestions;
                    updatedRdPrelimQas.roleInCompany = newAnswer;
                    setRdPrelimQas(RD.success(updatedRdPrelimQas));
                  }
                }}
              />
            </div>

            <div className="text-center mb-[60px]">
              <PrimaryButton
                type="submit"
                title="Next"
                disabled={!formComplete}
                onClick={() => onSubmit(completeQuestions)}
                style={{
                  backgroundColor: "#222222",
                  color: "#FAF7F2",
                  padding: "16px 20px 16px 20px",
                  opacity: formComplete ? "" : "30%",
                }}
              >
                <div className="flex items-center">
                  Next
                  <HiChevronRight />
                </div>
              </PrimaryButton>
            </div>
          </div>
        </div>
      )
    )
  );
};

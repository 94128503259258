import SaturnLogoNewWhite from "@/public/images/SaturnLogoNew-White.png";
import CrossIcon from "@/public/images/icons/cross-icon.png";
import { Backend } from "@/src/backend/Backend";
import { LoadingSpinner } from "@/src/loading";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const PeerReviewDialog: React.FC<{
  title: string;
  description: string;
  userId: string;
  onCancel: () => void;
}> = ({ title, description, userId, onCancel }) => {
  const { register, handleSubmit } = useForm<{ peerEmail: string }>();
  const [result, setResultWarning] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSubmit = (data: { peerEmail: string }) => {
    setIsLoading(true);
    setError(false);
    setResultWarning("");
    Backend.post<{ msg: string }>(`/request-peer-review`, data, "my")
      .then((r) => {
        console.log("RESULT OF START PEER ASSESSMENT! ", r);
        setResultWarning(r.msg);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("ERROR OF START PEER ASSESSMENT! ", e);
        setError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log("USER ID: ", userId);
  }, [userId]);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[99] bg-[#00000033] confirm-dialog overflow-y-auto">
      <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center ">
        <div className="opacity-25 absolute z-10 inset-0"></div>
        <form
          className="bg-[#222222] rounded-lg  bottom-0 z-[50]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex m-3 justify-end cursor-pointer ">
            <img
              src={CrossIcon}
              alt="cross-icon"
              className="w-[21px] h-[21px]"
              onClick={onCancel}
            />
          </div>
          <div className="flex flex-col items-center justify-center  h-[150px]">
            <img
              src={SaturnLogoNewWhite}
              alt="saturn-logo-new-white"
              className="w-[176px]"
            />
            <div className="text-center text-[24px] text-[#FAF6F0] font-gilroy font-[600]">
              Saturn Leadership™ 360 Report
            </div>
          </div>
          <div className="flex bg-[#FAF6F0] text-[#222222] p-[40px]">
            <div>
              <p className="font-semibold font-inter text-[21px] pb-4 ">
                {title}
              </p>
              <p className="font-inter text-[16px] pb-4">{description}</p>
              <input
                type="email"
                className="border-b border-[#C8B98C] bg-[#FAF6F0] py-4 px-1 text-start w-full"
                placeholder="Peer email"
                {...register("peerEmail", { required: true })}
              />
              <div className="pt-4">
                <p className="text-green-600 font-bold font-gilroy text-center">
                  {result}
                </p>
                {error && (
                  <p className="text-red-600  font-bold font-gilroy text-center">
                    There was an error, try again
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="text-center bg-[#FAF6F0] px-[40px] pb-[40px] rounded">
            <button
              id="confirm-delete-btn"
              type="submit"
              className="flex justify-center w-full h-[60px] px-[20px] py-[16px] bg-[#E3B735] text-[#222222] rounded-lg font-gilroy font-semibold text-[18px]"
            >
              {isLoading ? <LoadingSpinner dim={30} /> : "Send Peer Review"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

import { Backend } from "@/src/backend/Backend";
import { NavIntro } from "@/src/components/navs/nav-intro";
import { RD } from "@/src/prelude";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { useSearchParams } from "react-router-dom";

export const LoginPage: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useForm<{ email: string }>();
  const [params, _] = useSearchParams();
  const [submitResult, setSubmitResult] = React.useState<
    RD.RemoteData<any, { message: string; status: number }>
  >(RD.initial);

  console.log("PARAMS! ", params);

  function onSubmit(e: any) {
    e.preventDefault();
    const body = { email: e.target[0].value };
    setSubmitResult(RD.pending);
    Backend.post<{ message: string; status: number }>("login", body)
      .then((r) => {
        console.log("RESPONSE! ", r);
        setSubmitResult(RD.success(r));
      })
      .catch((e) => {
        console.log("ERROR! ", e);
        setSubmitResult(RD.failure(e));
      });
  }

  return (
    <>
      <NavIntro />
      <div className="flex-1 flex bg-[#FAF6F0]">
        <div className="flex-1 relative">
          <div className="inset-0 flex flex-col  mt-16 items-center ">
            <div className="flex flex-col items-center max-w-3xl gap-3 w-[500px]">
              <h5 className=" text-5xl font-bodonixt  mb-4 text-center">
                Welcome back
              </h5>
              <form
                className="flex flex-col items-center gap-6 w-full"
                onSubmit={(e) => {
                  onSubmit(e);
                }}
              >
                <span
                  className="font-gilroy text-[#222] text-lg font-semibold leading-6 text-center mt-8"
                  style={{ maxWidth: "645px" }}
                >
                  {`Please, provide your email address to receive a login link.`}
                </span>
                <input
                  type="email"
                  className="border-b border-[#C8B98C] bg-[#FAF6F0] py-4 px-16 text-center w-full"
                  placeholder="Type your email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-red-500 font-gilroy">
                    Email is required
                  </span>
                )}
                {pipe(
                  submitResult,
                  RD.fold(
                    () => <></>,
                    () => (
                      <div className="text-green-600">
                        Sending verification email...
                      </div>
                    ),
                    () => <></>,
                    (r) => {
                      if (r.status < 400) {
                        return (
                          <span className="text-green-600 font-bold font-gilroy">
                            {`An email verification is on it's way. Please check your email over the next few minutes to verify.`}
                          </span>
                        );
                      }
                      return (
                        <span className="text-red-500 font-gilroy">
                          {r.message}
                        </span>
                      );
                    }
                  )
                )}
                <div className="flex w-full">
                  <button
                    className="px-8  rounded-md grow-0 w-full"
                    type="submit"
                    title="Next"
                    style={{
                      backgroundColor: "#222222",
                      color: "#FAF7F2",
                      marginTop: "10px",
                      padding: "16px 20px 16px 20px",
                      justifyContent: "center",
                    }}
                  >
                    <div className="flex font-gilroy items-center text-lg justify-center">
                      {RD.isPending(submitResult) ? (
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="20"
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

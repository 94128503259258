import React from "react";
import QuestionLabel from "./question-label";

const TextQuestion: React.FC<{
  question: string;
  answer: string;
  setAnswer: (newAnswer: string) => void;
  name?: string;
}> = ({ question, answer, setAnswer, name }) => {
  return (
    <div className="flex flex-col items-start gap-8">
      <QuestionLabel question={question} />
      <div className="w-full">
        <input
          type="text"
          value={answer}
          placeholder="Type your answer"
          name={name ? name : question}
          id="answer"
          className="border-b border-[#C8B98C] font-gilroy text-[18px] bg-[#FAF6F0] text-start px-4 py-2 w-full"
          min={"0"}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TextQuestion;

export const positiveScorePercentage = (
  valuePercentage: number | undefined
) => {
  if (valuePercentage)
    if (valuePercentage < 1) {
      return "#E8E8E8";
    } else if (valuePercentage >= 1 && valuePercentage < 15) {
      return "#DDECD6";
    } else if (valuePercentage >= 15 && valuePercentage < 30) {
      return "#BCDBAD";
    } else if (valuePercentage >= 30 && valuePercentage < 45) {
      return "#9BCA86";
    } else if (valuePercentage >= 45 && valuePercentage < 60) {
      return "#53903D";
    } else if (valuePercentage >= 60) {
      return "#457733";
    }
  return "#E8E8E8";
};

export const negativeScorePercentage = (
  valuePercentage: number | undefined
) => {
  if (valuePercentage)
    if (valuePercentage < 1) {
      return "#E8E8E8";
    } else if (valuePercentage >= 1 && valuePercentage < 15) {
      return "#FFD3C2";
    } else if (valuePercentage >= 15 && valuePercentage < 30) {
      return "#FFA299";
    } else if (valuePercentage >= 30 && valuePercentage < 45) {
      return "#E96C5E";
    } else if (valuePercentage >= 45 && valuePercentage < 60) {
      return "#EA5545";
    } else if (valuePercentage >= 60) {
      return "#EB3D26";
    }
  return "#E8E8E8";
};

export const averageScores = (value: number | undefined) => {
  if (value)
    if (value < 1.75) {
      return "#EB3D26";
    } else if (value >= 1.75 && value < 2.5) {
      return "#E96C5E";
    } else if (value >= 2.5 && value < 3.0) {
      return "#FFD3C2";
    } else if (value === 3.0) {
      return "#E8E8E8";
    } else if (value >= 3.0 && value < 3.5) {
      return "#DDECD6";
    } else if (value >= 3.5 && value < 4.25) {
      return "#9BCA86";
    } else if (value >= 4.25) {
      return "#457733";
    }
  return "#E8E8E8";
};

export const selfAvgDifference = (value: number | undefined) => {
  if (value)
    if (value < -1.5) {
      return "#4691CC";
    } else if (value >= -1.5 && value <= -1) {
      return "#8BBAE0";
    } else if (value > -1 && value <= 0) {
      return "#D7E8F5";
    } else if (value === 0) {
      return "#E8E8E8";
    } else if (value > 0 && value <= 1) {
      return "#FEE0D1";
    } else if (value > 1 && value <= 1.5) {
      return "#FCC4A6";
    } else if (value >= 1.5) {
      return "#F37235";
    }
  return "#E8E8E8";
};

export const ratings = (value: number | undefined) => {
  if (value)
    if (value === 1) {
      return "#EB3D26";
    } else if (value === 2) {
      return "#E96C5E";
    } else if (value === 3) {
      return "#FFD3C2";
    } else if (value === 4) {
      return "#9BCA86";
    } else if (value === 5) {
      return "#457733";
    }
  return "#E8E8E8";
};

export const votes = (value: number | undefined) => {
  if (value)
    if (value === 0) {
      return "#FFF2ED";
    } else if (value === 1) {
      return "#FFD3C2";
    } else if (value === 2) {
      return "#FFA299";
    } else if (value === 3) {
      return "#EA5545";
    } else if (value >= 4) {
      return "#EB3D26";
    }
  return "#E8E8E8";
};

import { Backend } from "@/src/backend/Backend";
import { SliAssessmentSurveyForm } from "@/src/components/sli-assessment-form";
import { RD } from "@/src/prelude";
import { SliaQuestion } from "@/src/types/index.types";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const StandaloneAssessmentPage: React.FC<{
  isPeerAssessment: boolean;
}> = ({ isPeerAssessment }) => {
  const params = useParams<{ sessionId: string }>();
  const [rdQuestions, setRdQuestions] = React.useState<
    RD.RemoteData<any, SliaQuestion[]>
  >(RD.initial);
  const nav = useNavigate();

  useEffect(() => {
    Backend.get<SliaQuestion[]>(
      `u/sli-sessions/${params.sessionId}/questions`
    ).then((res) => {
      console.log("RES! ", res);
      setRdQuestions(RD.success(res));
    });
  }, []);
  return (
    <div>
      {RD.isSuccess(rdQuestions) && (
        <SliAssessmentSurveyForm
          questions={rdQuestions.value.map((qa) => ({
            id: qa.id,
            question: isPeerAssessment ? qa.peer_question : qa.question,
            pillar_name: qa.pillar_name,
            answer: null,
          }))}
          onSave={(answers) => {
            Backend.post(
              `u/sli-sessions/${params.sessionId}/self/save`,
              answers
            )
              .then((r) => {
                console.log("SAVE RESULT! ", r);
              })
              .catch((er) => {
                console.log("ERROR! ", er);
              });
          }}
          onSaved={() => {}}
          onSubmit={(answers) => {
            Backend.post(
              `u/sli-sessions/${params.sessionId}/self/submission`,
              answers
            )
              .then((r) => {
                console.log("RESULT! ", r);
                nav("/my/dashboard/reports");
              })
              .catch((er) => {
                console.log("ERROR! ", er);
              });
          }}
        />
      )}
    </div>
  );
};

import { Sli360ReportTypes } from "@/src/types/360report.types";
import {
  averageScores,
  negativeScorePercentage,
  positiveScorePercentage,
  votes,
} from "@/src/utils/scoresColors";
import { useEffect, useState } from "react";

export const AveragesVotesChart: React.FC<{
  pillarSummaryPage: Sli360ReportTypes.PillarSumarryPage;
}> = ({ pillarSummaryPage }) => {
  const [topStrenghts, setTopStrengths] =
    useState<Sli360ReportTypes.Rating[]>();
  const [topImprovement, setTopImprovement] =
    useState<Sli360ReportTypes.Rating[]>();

  const styleBorderRounded = (index: number) => ({
    borderTopRightRadius: index === 0 ? "5px" : "0",
    borderTopLeftRadius: index === 0 ? "5px" : "0",
    borderBottomLeftRadius:
      index === pillarSummaryPage.length - 1 ? "5px" : "0",
    borderBottomRightRadius:
      index === pillarSummaryPage.length - 1 ? "5px" : "0",
  });

  const combinateStylesObjects = (
    object1: React.CSSProperties,
    object2: React.CSSProperties
  ) => {
    return { ...object1, ...object2 };
  };

  useEffect(() => {
    const strengthsOrderByAvg: Sli360ReportTypes.Rating[] = [];
    const improvementOrderByVotes: Sli360ReportTypes.Rating[] = [];

    pillarSummaryPage.forEach((pillar) => {
      pillar.ratings.forEach((rating) => {
        strengthsOrderByAvg.push(rating);
        improvementOrderByVotes.push(rating);
      });
    });

    strengthsOrderByAvg.sort((a, b) => b.average_rating - a.average_rating);

    improvementOrderByVotes.sort((a, b) => b.final_votes - a.final_votes);

    setTopStrengths(strengthsOrderByAvg.slice(0, 15));
    setTopImprovement(improvementOrderByVotes.slice(0, 15));
  }, []);
  return (
    <div className="flex flex-row p-8 h-1">
      <table className="table-fixed">
        <thead>
          <tr className="h-[100px] text-[13px] font-inter ">
            <th className="w-[250px]">
              <div className="h-[120px]">
                <div className="h-[100%] justify-between grid grid-cols-1">
                  <div className=" flex flex-col items-center justify-center bg-[#F4F0E5] text-[13px] py-[12px] border border-[#B1B1B1] rounded-md items-center m-1">
                    <div className="self-center">Top Strengths</div>
                    <p className="font-medium">(Based on Average)</p>
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[128px]">
              <div className=" mx-1 w-[128px]">
                <div className=" h-[52px] flex items-center justify-center bg-[#F4F0E5]  border border-[#B1B1B1] rounded-md items-center mb-1 mx-[2px] ">
                  Strengths & Weaknesses by %
                </div>
                <div className="h-[52px] justify-between grid grid-cols-2">
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center mt-1 mx-[2px] w-[60px]">
                    <div>Strength</div>
                    <div className="flex mt-1  pb-1 w-full justify-center self-center items-center bg-[#9BCA86] rounded">
                      (4s & 5s)
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center  mt-1 mx-[2px] w-[60px]">
                    <div>Weakness</div>
                    <div className="flex pb-1 items-center w-full justify-center self-center mt-1 bg-[#F9A77D] rounded">
                      (1s & 2s)
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[65px] mx-1 w-[126px]">
              <div className="h-[52px] flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md mb-1 mx-[2px]">
                Ratings
              </div>
              <div className="h-[52px] align-content-around justify-between grid grid-cols-2">
                <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center col-span-1   mt-1 mx-[2px] w-[60px]">
                  Self Rating
                </div>
                <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center col-span-1  mt-1 mx-[2px] w-[60px]">
                  Average Rating
                </div>
              </div>
            </th>

            <th className="w-[65px]">
              <div className=" mx-1 w-[64px]">
                <div className=" h-[52px]flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md mb-1 mx-[2px]">
                  Votes
                </div>
                <div className="h-[52px] align-content-around justify-between grid grid-cols-2 text-[11px]">
                  <div className="flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md items-center col-span-1   mt-1 mx-[2px] w-[60px]">
                    Final Votes to Prioritize
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {topStrenghts &&
            topStrenghts.map((ts, index) => {
              return (
                <tr key={index} className="font-inter text-[12px] max-h-[70px]">
                  <td
                    className={`min-w-[300px] border border-[#B1B1B1] h-[90px] self-end grid grid-cols-8 mx-1 p-0 mt-${
                      index === 0 ? "2" : "0"
                    }`}
                    style={styleBorderRounded(index)}
                  >
                    <div className="bg-[#FAF6F0]  px-[10px]  py-[6px] justify-center flex items-center font-inter col-span-1">{`${
                      pillarSummaryPage.find((pillar) => {
                        return pillar.ratings.some(
                          (rating) => rating.attribute === ts.attribute
                        );
                      })?.pillar.number
                    }.${ts.order_in_pillar}`}</div>
                    <div className="bg-[#FDFCFA]  px-[10px] py-[6px] border-l  border-[#B1B1B1] flex items-center col-span-7">{`${ts.attribute}`}</div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"} p-0`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div className="grid grid-cols-2 h-[90px] font-inter font-semibold text-[20px] w-[128px] ml-1">
                      <div
                        className="flex items-center justify-center bg-[#457733]   border border-[#FAFAFA] items-center mx-[2px] w-[60px]"
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: positiveScorePercentage(
                              ts.weakness
                            ),
                          }
                        )}
                      >
                        {ts.strength}%
                      </div>
                      <div
                        className="flex items-center justify-center  border border-[#FAFAFA] items-center  mx-[2px] w-[60px]"
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: negativeScorePercentage(
                              ts.weakness
                            ),
                          }
                        )}
                      >
                        {ts.weakness}%
                      </div>
                    </div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"}  p-0`}
                    style={{ verticalAlign: "bottom", height: "60px" }}
                  >
                    <div className="grid grid-cols-2 h-[90px] font-inter font-semibold text-[20px] w-[126px] ml-1">
                      <div
                        className={`flex items-center justify-center  border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: averageScores(ts.self_rating),
                          }
                        )}
                      >
                        {ts.self_rating}
                      </div>
                      <div
                        className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: averageScores(ts.average_rating),
                          }
                        )}
                      >
                        {ts.average_rating}
                      </div>
                    </div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"}  p-0`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div className="grid grid-cols-1 h-[90px] font-inter font-semibold text-[20px] ml-1 w-[64px]">
                      <div
                        className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: votes(ts.final_votes),
                          }
                        )}
                      >
                        {ts.final_votes}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <table className="table-fixed">
        <thead>
          <tr className="h-[100px] text-[13px] font-inter ">
            <th className="w-[250px]">
              <div className="h-[120px]">
                <div className="h-[100%] justify-between grid grid-cols-1">
                  <div className=" flex flex-col items-center justify-center bg-[#F4F0E5] text-[13px] py-[12px] border border-[#B1B1B1] rounded-md items-center m-1">
                    <div className="self-center">
                      Top Improvement Opportunities
                    </div>
                    <p className="font-medium">(Based on Number of Votes)</p>
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[128px]">
              <div className=" mx-1 w-[128px]">
                <div className=" h-[52px] flex items-center justify-center bg-[#F4F0E5]  border border-[#B1B1B1] rounded-md items-center mb-1 mx-[2px] ">
                  Strengths & Weaknesses by %
                </div>
                <div className="h-[52px] justify-between grid grid-cols-2">
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center mt-1 mx-[2px] w-[60px]">
                    <div>Strength</div>
                    <div className="flex mt-1  pb-1 w-full justify-center self-center items-center bg-[#9BCA86] rounded">
                      (4s & 5s)
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center  mt-1 mx-[2px] w-[60px]">
                    <div>Weakness</div>
                    <div className="flex pb-1 items-center w-full justify-center self-center mt-1 bg-[#F9A77D] rounded">
                      (1s & 2s)
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[65px] mx-1 w-[126px]">
              <div className="h-[52px] flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md mb-1 mx-[2px]">
                Ratings
              </div>
              <div className="h-[52px] align-content-around justify-between grid grid-cols-2">
                <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center col-span-1   mt-1 mx-[2px] w-[60px]">
                  Self Rating
                </div>
                <div className="flex items-center justify-center bg-[#F4F0E5] text-[11px] border border-[#B1B1B1] rounded-md items-center col-span-1  mt-1 mx-[2px] w-[60px]">
                  Average Rating
                </div>
              </div>
            </th>

            <th className="w-[65px]">
              <div className=" mx-1 w-[64px]">
                <div className=" h-[52px]flex items-center justify-center bg-[#F4F0E5]  py-[12px] border border-[#B1B1B1] rounded-md mb-1 mx-[2px]">
                  Votes
                </div>
                <div className="h-[52px] align-content-around justify-between grid grid-cols-2 text-[11px]">
                  <div className="flex items-center justify-center bg-[#F4F0E5] border border-[#B1B1B1] rounded-md items-center col-span-1   mt-1 mx-[2px] w-[60px]">
                    Final Votes to Prioritize
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {topImprovement &&
            topImprovement.map((ts, index) => {
              return (
                <tr key={index} className="font-inter text-[12px] max-h-[90px]">
                  <td
                    className={`min-w-[300px] border border-[#B1B1B1] h-[90px] self-end grid grid-cols-8 mx-1 p-0 mt-${
                      index === 0 ? "2" : "0"
                    }`}
                    style={styleBorderRounded(index)}
                  >
                    <div className="bg-[#FAF6F0]  px-[10px]  py-[6px] justify-center flex items-center font-inter col-span-1">{`${
                      pillarSummaryPage.find((pillar) => {
                        return pillar.ratings.some(
                          (rating) => rating.attribute === ts.attribute
                        );
                      })?.pillar.number
                    }.${ts.order_in_pillar}`}</div>
                    <div className="bg-[#FDFCFA]  px-[10px] py-[6px] border-l  border-[#B1B1B1] flex items-center col-span-7">{`${ts.attribute}`}</div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"} p-0`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div className="grid grid-cols-2 h-[90px] font-inter font-semibold text-[20px] w-[128px] ml-1">
                      <div
                        className="flex items-center justify-center bg-[#457733]   border border-[#FAFAFA] items-center mx-[2px] w-[60px]"
                        style={styleBorderRounded(index)}
                      >
                        {ts.strength}%
                      </div>
                      <div
                        className="flex items-center justify-center  border border-[#FAFAFA] items-center  mx-[2px] w-[60px]"
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: negativeScorePercentage(
                              ts.weakness
                            ),
                          }
                        )}
                      >
                        {ts.weakness}%
                      </div>
                    </div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"}  p-0`}
                    style={{ verticalAlign: "bottom", height: "60px" }}
                  >
                    <div className="grid grid-cols-2 h-[90px] font-inter font-semibold text-[20px] w-[126px] ml-1">
                      <div
                        className={`flex items-center justify-center  border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: averageScores(ts.self_rating),
                          }
                        )}
                      >
                        {ts.self_rating}
                      </div>
                      <div
                        className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: averageScores(ts.average_rating),
                          }
                        )}
                      >
                        {ts.average_rating}
                      </div>
                    </div>
                  </td>
                  <td
                    className={`mt-${index === 0 ? "2" : "0"}  p-0`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div className="grid grid-cols-1 h-[90px] font-inter font-semibold text-[20px] ml-1 w-[64px]">
                      <div
                        className={`flex items-center justify-center border border-[#FAFAFA] items-center mx-1 w-[60px]`}
                        style={combinateStylesObjects(
                          styleBorderRounded(index),
                          {
                            backgroundColor: votes(ts.final_votes),
                          }
                        )}
                      >
                        {ts.final_votes}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

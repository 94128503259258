import { SaturnBarChart } from "@/src/components/charts/saturn-bar-chart/opportunities-saturn-bar-chart";
import { useSampleSli360Report } from "./sample-sli-360.context";
import { SaturnPieChart } from "@/src/components/charts/saturn-pie-chart/saturn-pie-chart";
import ScoreOnReport from "@/src/components/charts/score-on-report/score-on-report";
import { responsesTypeAmount } from "@/src/components/charts/saturn-pie-chart/saturn-pie-chart-pdf";
import { useEffect } from "react";

export const SampleSli360LeadershipSummaryPage: React.FC<
  Record<string, never>
> = () => {
  const { leadershipSummaryPage } = useSampleSli360Report();
  const { responseSummary } = leadershipSummaryPage;

  const leadershipSummaryData = [
    {
      color: "#83BC75",
      title: "Strengths",
    },
    {
      color: "#FEB165",
      title: "Functional",
    },
    {
      color: "#E64925",
      title: "Weaknesses",
    },
  ];

  const responsesTypeAmounts: responsesTypeAmount[] = [
    { type: "Execs", amount: responseSummary.execs },
    { type: "Family", amount: responseSummary.family },
    { type: "Direct's Directs", amount: responseSummary.directsDirects },
  ];

  useEffect(() => {
    console.log(leadershipSummaryPage.strengthAndImprovementOpportunities);
  }, [leadershipSummaryPage.strengthAndImprovementOpportunities]);
  return (
    <div className="">
      <div className="flex gap-4 p-10 flex-row bg-[#F8F8F8] bg-[#FFFFFF]">
        <div>
          <ScoreOnReport
            title="Overall Leadership Score"
            score={Number.parseFloat(
              leadershipSummaryPage.overallLeadershipScore
            )}
          />
        </div>
        <div className="border border-[#E1E1E1]-100 p-1 w-[50%] max-h-[251px]">
          <div>
            <div
              style={{
                fontFamily: "inter",
                fontWeight: "bold",
                fontSize: "20px",
                color: "#222222",
                textAlign: "center",
              }}
            >
              Response Summary
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex-col self-center text-right">
              <div
                style={{
                  fontFamily: "Inter",
                  fontWeight: "semibold",
                  fontSize: "10px",
                  marginBottom: "10px",
                }}
              >
                TOTAL RESPONSES:{" "}
                {leadershipSummaryPage.responseSummary.totalResponses}
              </div>
              {responsesTypeAmounts.map((response) => {
                return (
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "semibold",
                      fontSize: "10px",
                      color: "#666666",
                      marginBottom: "2px",
                    }}
                  >
                    {response.type}: {response.amount}
                  </div>
                );
              })}
            </div>
            <div className="w-[200px]">
              <SaturnPieChart
                data={[
                  {
                    color: "#83BC75",
                    title: "Strengths",
                    value: responseSummary.pctStrengths,
                  },
                  {
                    color: "#FEB165",
                    title: "Functional",
                    value: responseSummary.pctFunctional,
                  },
                  {
                    color: "#E64925",
                    title: "Weaknesses",
                    value: responseSummary.pctWeaknesses,
                  },
                ]}
              />
            </div>

            <div className="flex flex-col self-center">
              {leadershipSummaryData.map((item, index) => (
                <div
                  key={index + item.title}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px 5px 5px 5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: "13px",
                      height: "13px",
                      paddingTop: "3px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      height: "13px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "inter",
                        fontWeight: "500",
                        fontSize: "10px",
                        color: "#222222",
                        paddingLeft: "2px",
                        alignSelf: "center",
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="p-10  w-full bg-[#FFFFFF]">
        <div className="flex flex-col justify-center border border-[#E1E1E1]-100 p-1  flex-column max-h-[500px] max-w-[950px]">
          <div
            style={{
              fontFamily: "inter",
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: "20px",
              color: "#222222",
              textAlign: "center",
            }}
          >
            Strengths & Improvement Opportunities
          </div>
          <div className="w-full h-[500px] ">
            <SaturnBarChart
              labels={leadershipSummaryPage.strengthAndImprovementOpportunities.map(
                (pillar, index) => {
                  return index + 1 + " " + pillar.pillar;
                }
              )}
              data={leadershipSummaryPage.strengthAndImprovementOpportunities.map(
                (op) => {
                  return {
                    majorWakness: op.pctWeaknessAndFunctional,
                    needsImprovement: op.pctWeaknessAndFunctional,
                    functional: op.pctWeaknessAndFunctional,
                    good: op.pctStrengths,
                    strong: op.pctStrengths,
                  };
                }
              )}
              width="1000px"
              height="400px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { Backend } from "@/src/backend/Backend";
import { BackButton, PrimaryButton } from "@/src/components/buttons";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PeerOnboardFinalCommentsPage: React.FC = () => {
  const nav = useNavigate();
  const [comments, setComments] = React.useState<string>("");

  return (
    <div className="w-full">
      <BackButton navToBack="/peer-onboard/peer-assessment/7" />
      <div className="flex-1 flex flex-col gap-8 items-center justify-center">
        <h1 className="self-center text-4xl font-bodonixt text-black text-center">
          Comments
        </h1>
        <div
          className={`
            flex-1 flex flex-col  self-center items-start
            `}
        >
          <div className="font-gilroy text-lg font-semibold pl-14 pr-14 p-[20px]">
            <div>
              Please write briefly the comments or observations you have for Amy
              Williams.
            </div>
          </div>
        </div>
        <textarea
          placeholder="Type something"
          className="border-b border-[#C8B98C] text-[#C8B98C] placeholder-[#C8B98C] font-[500] font-gilroy bg-[#FAF6F0] p-2 w-[600px] h-[200px]"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <PrimaryButton
          onClick={() => {
            if (comments.length > 0) {
              const body = { comments };
              Backend.post(`/final-comments`, body, "peer-onboard").then(
                (r) => {
                  console.log(r);
                  nav(`/peer-onboard-complete/congrats`);
                }
              );
            }
          }}
          style={{
            width: "600px",
            backgroundColor: "#222222",
            color: "#FAF7F2",
            padding: "16px 20px 16px 20px",
            opacity: comments.length > 0 ? "" : "30%",
          }}
        >
          <div className="flex items-center justify-center">Submit</div>
        </PrimaryButton>
      </div>
    </div>
  );
};

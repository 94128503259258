import { LStorage } from "@/src/lstorage";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

export const MyLayout: React.FC = () => {
  const url = useLocation();
  const queryParams = new URLSearchParams(url.search);
  const mbTokenParam = queryParams.get("jwt");
  const [myToken, setMyToken] = useState<string | null>(null);
  useEffect(() => {
    if (mbTokenParam) {
      setMyToken(mbTokenParam);
      LStorage.setJwt(mbTokenParam);
    } else {
      const mbToken = LStorage.get("jwt");
      setMyToken(mbToken);
    }
  }, [mbTokenParam]);

  if (!myToken) return <div>Loading...</div>;

  return (
    <div className="flex flex-col w-screen h-screen ">
      <Outlet />
    </div>
  );
};

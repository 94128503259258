import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export const AdminRootPage: React.FC<{}> = ({ }) => {
  const nav = useNavigate()
  useEffect(() => {
    nav('/admin/dashboard/user-reports')
  }, [])
  return (
    <></>
  )
}
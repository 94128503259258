export const ReportCoverPage: React.FC<{ forUserEmail: string }> = ({
  forUserEmail,
}) => {
  return (
    <div className="w-full flex-1 flex flex-col items-center justify-center bg-white p-8 font-hind">
      <img
        src={"/images/saturn-leadership-logo.png"}
        alt="sli"
        className="w-72 object-contain mb-8"
      />
      <h1 className="font-semibold">{`Report for ${forUserEmail}`}</h1>
    </div>
  );
};

export const PageContainer: React.FC<{
  children: React.ReactNode;
  pageNumber: number;
  withoutHeader?: boolean;
}> = ({ children, withoutHeader, pageNumber }) => {
  return (
    <div
      className={`h-screen flex flex-col items-center border pageContainer`}
      style={
        {
          // width: '60px'
        }
      }
      id={`page-${pageNumber}`}
    >
      {!withoutHeader && <SaReportHeader />}
      <div className="flex flex-1 flex-col px-8">{children}</div>
    </div>
  );
};

const SaReportHeader: React.FC = () => {
  return (
    <div className={`flex w-full relative mb-8 pt-8`}>
      <div>
        {/* <Image
          src={saturnLeadershipLogo}
          alt="sli"
          className="w-24 object-contain"
        /> */}
      </div>
      <div className="absolute top-8 left-0 right-0 bottom-0 flex justify-center items-center font-bold text-xl font-hind">
        <h1>Saturn Leadership Index™ (SLI) Self-Assessment Results</h1>
      </div>
    </div>
  );
};

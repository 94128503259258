import { pipe } from "fp-ts/lib/function";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Backend } from "../../backend/Backend";
import { O, RD } from "../../prelude";
import NumberQuestion from "@/src/components/questions/number-question";
import YesNoQuestion from "@/src/components/questions/yes-no-question";
import { PrimaryButton } from "@/src/components/buttons";
import { HiChevronRight } from "react-icons/hi";

export type PreliminaryQuestion = {
  question: {
    questionSlug: string;
    questionText: string;
    answerType: "yes-no" | "number";
  };
  progressPct: number;
  answer: "yes" | "no" | number | null;
};

const initial = {
  opacity: 0,
  justifyContent: "flex-end",
};

const entered = {
  opacity: 1,
  scale: 1,
};

const exited = {
  opacity: 0,
  transitionEnd: {
    display: "none",
  },
};

export const SelfPreliminaryQuestionsPage: React.FC = () => {
  const params = useParams<{ questionSlug: string }>();
  const { questionSlug } = params;
  const nav = useNavigate();
  const [rdQuestions, setRdQuestions] = React.useState<
    RD.RemoteData<any, PreliminaryQuestion[]>
  >(RD.initial);
  const [enteredExitedState, setEnteredExitedState] =
    useState<EnteredExitedState>("initial");
  const animate = useMemo(() => {
    switch (enteredExitedState) {
      case "initial":
        return initial;
      case "entered":
        return entered;
      case "exited":
        return exited;
    }
  }, [enteredExitedState]);

  const areAnswersCompleted = (completeQuestions: PreliminaryQuestion[]) => {
    return (
      completeQuestions.filter((question) => {
        return (
          question.answer?.toString().length === 0 ||
          question.answer?.toString() === "NaN" ||
          typeof question.answer === "object" ||
          question.answer === undefined
        );
      }).length < 1
    );
  };
  const sendForm = (completeQuestions: PreliminaryQuestion[]) => {
    const body = completeQuestions.map((completeQuestion) => {
      return {
        questionSlug: completeQuestion.question.questionSlug,
        answer: completeQuestion.answer?.toString(),
      };
    });
    Backend.post<{ progressPct: number }>(
      `/preliminary-qas-actions/submit`,
      body,
      "self-onboard"
    )
      .then((r) => {
        console.log("GOT RESULT! ", r);
        nav(`/self-onboard/overall-leadership-survey`);
      })
      .catch((e) => {
        console.log("ERROR ENTER");
        console.error(e);
      });
  };
  useEffect(() => {
    console.log("SET ENTERED EXITED STATE: ", enteredExitedState);
  }, [enteredExitedState]);

  useEffect(() => {
    setEnteredExitedState("initial");
    const r = setTimeout(() => {
      setEnteredExitedState("entered");
    }, 300);

    return () => {
      clearTimeout(r);
    };
  }, [questionSlug]);

  useEffect(() => {
    setRdQuestions(RD.pending);
    Backend.get<PreliminaryQuestion[]>(`/preliminary-qas`, "self-onboard")
      .then((r) => {
        console.log("GOT PROGRESS: ", r);
        setRdQuestions(RD.success(r));
      })
      .catch((e) => {
        console.error(e);
      });
  }, [questionSlug]);

  return pipe(
    rdQuestions,
    RD.toOption,
    O.fold(
      () => <></>,
      (completeQuestions) => (
        <div className="flex flex-col p-8">
          <div
            className={`
            flex-1 flex flex-col  self-center items-start
            `}
          >
            <div className="font-gilroy text-lg bg-[#F4F0E5] font-semibold pl-14 pr-14 mb-[50px] p-[50px]">
              <div>
                In order to ask you the right questions, we need to understand
                more about your role.
              </div>
              <div>
                Please answer the following questions to the best of your
                ability.
              </div>
            </div>

            <motion.div
              layout
              initial={{ opacity: 0, scale: 0.5 }}
              animate={animate}
              transition={{
                duration: 0.2,
                delay: 0.0,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              {completeQuestions.map(
                (completeQuestion: PreliminaryQuestion, index: number) => {
                  return (
                    <div
                      className="rounded-2xl flex flex-col bg-[#FAF6F0] mb-[60px]"
                      key={index}
                    >
                      {completeQuestion.question.answerType === "yes-no" && (
                        <YesNoQuestion
                          question={completeQuestion.question.questionText}
                          answer={
                            completeQuestion.answer as "yes" | "no" | null
                          }
                          setAnswer={(
                            newAnswer: number | "yes" | "no" | null
                          ) => {
                            const updatedRdQuestions: PreliminaryQuestion[] =
                              completeQuestions;
                            updatedRdQuestions[index].answer = newAnswer;
                            setRdQuestions(RD.success(updatedRdQuestions));
                          }}
                        />
                      )}
                      {completeQuestion.question.answerType === "number" && (
                        <NumberQuestion
                          question={completeQuestion.question.questionText}
                          answer={completeQuestion.answer as number}
                          setAnswer={(newAnswer: number) => {
                            const updatedRdQuestions: PreliminaryQuestion[] =
                              completeQuestions;
                            updatedRdQuestions[index].answer = newAnswer;
                            setRdQuestions(RD.success(updatedRdQuestions));
                          }}
                        />
                      )}
                    </div>
                  );
                }
              )}
              <div className="text-center mb-[60px]">
                <PrimaryButton
                  type="submit"
                  title="Next"
                  disabled={!areAnswersCompleted(completeQuestions)}
                  style={{
                    backgroundColor: "#222222",
                    color: "#FAF7F2",
                    padding: "16px 20px 16px 20px",
                    opacity: !areAnswersCompleted(completeQuestions)
                      ? "30%"
                      : "",
                  }}
                  onClick={() => sendForm(completeQuestions)}
                >
                  <div className="flex items-center">
                    Next
                    <HiChevronRight />
                  </div>
                </PrimaryButton>
              </div>
            </motion.div>
          </div>
        </div>
      )
    )
  );
};
type EnteredExitedState = "initial" | "entered" | "exited";

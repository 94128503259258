import { ErrorTracking } from "../error-tracking";
import { LStorage } from "../lstorage";
import { E } from "../prelude";

export module Backend {
  const BASE_URL = `${import.meta.env.VITE_API_URL}`;
  const BASE_ADMIN_URL = `${BASE_URL}admin/`;

  console.log("ENV MODE! ", import.meta.env.MODE);
  console.log("BASE URL! ", BASE_URL);

  function setLocalStorageFromHeaders(headers: Headers) {
    // If a header begins with `set-`, set the corresponding local storage value as the name of the set header
    // For example, if the header is `set-jwt`, set the local storage value `jwt` to the value of the header

    headers.forEach((value, key) => {
      if (key.startsWith("set-")) {
        const localStorageKey = key.replace("set-", "") as LStorage.Key;
        LStorage.set(localStorageKey, value);
      }
    });
  }

  function appendLocalStorageValuesToHeaders(headers: Headers) {
    // For every value in local storage, we set it's value as a header with the name `x-${key}`
    for (const [key, value] of Object.entries(localStorage)) {
      console.log("APPENDING KEY! ", key);
      if (key === "jwt") {
        headers.append("Authorization", `Bearer ${value}`);
      } else {
        // headers.append(`x-${key}`, value);
      }
    }
  }

  export type ResponseError<E = unknown> = {
    status: number;
    body: E;
  };

  export async function getE<V, E = unknown>(
    endpt: string,
    isNotRest?: string,
    customJwt?: string
  ): Promise<E.Either<ResponseError<E>, V>> {
    const resp = await mkGetRequest(endpt, isNotRest, customJwt);

    const resBody = handleResponseE<V, E>(resp);

    return resBody;
  }

  async function mkGetRequest(
    endpt: string,
    isNotRest?: string,
    customJwt?: string
  ) {
    const url = `${BASE_URL}${isNotRest ?? "rest/"}${endpt}`;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    if (customJwt) {
      headers.append("Authorization", `Bearer ${customJwt}`);
    } else {
      appendLocalStorageValuesToHeaders(headers);
    }

    const reqInit: RequestInit = {
      method: "GET",
      mode: "cors",
      headers,
    };

    const resp = await fetch(url, reqInit);

    return resp;
  }

  export async function get<V>(
    endpt: string,
    isNotRest?: string,
    customJwt?: string
  ): Promise<V> {
    const resp = await mkGetRequest(endpt, isNotRest, customJwt);

    const resBody = handleResponse<V>(resp);

    return resBody;
  }

  async function handleResponse<V>(resp: Response): Promise<V> {
    if (!resp.ok) {
      const resBody: unknown = await resp.json();
      const exception = {
        status: resp.status,
        body: resBody,
      };
      ErrorTracking.captureException(exception);
      throw exception;
    }

    const resBody: V = await resp.json();

    setLocalStorageFromHeaders(resp.headers);

    return resBody;
  }

  async function handleResponseE<V, E = unknown>(
    resp: Response
  ): Promise<E.Either<ResponseError<E>, V>> {
    if (!resp.ok) {
      const resBody: E = await resp.json();
      return E.left({
        status: resp.status,
        body: resBody,
      });
    }
    const resBody: V = await resp.json();

    setLocalStorageFromHeaders(resp.headers);

    return E.right(resBody);
  }

  async function mkPostRequest(
    endpt: string,
    body: Object,
    isNotRest?: string
  ) {
    const url = `${BASE_URL}${isNotRest ?? "rest/"}${endpt}`;
    console.log("URL! ", url);
    console.log("BASE URL! ", BASE_URL);

    const headers: HeadersInit = new Headers();

    headers.append("Content-Type", "application/json");
    appendLocalStorageValuesToHeaders(headers);

    const reqInit: RequestInit = {
      method: "POST",
      headers,
      mode: "cors",
      body: JSON.stringify(body),
    };

    const resp = await fetch(url, reqInit);

    return resp;
  }

  export async function post<V>(
    endpt: string,
    body: Object,
    isNotRest?: string
  ): Promise<V> {
    const resp = await mkPostRequest(endpt, body, isNotRest);

    const resBody = handleResponse<V>(resp);

    return resBody;
  }

  export async function postE<V, E = unknown>(
    endpt: string,
    body: Object,
    isNotRest?: string
  ): Promise<E.Either<ResponseError<E>, V>> {
    const resp = await mkPostRequest(endpt, body, isNotRest);

    const resBody = handleResponseE<V, E>(resp);

    return resBody;
  }

  export async function deleteMy<V>(
    endpt: string,
    isNotMy?: string
  ): Promise<V> {
    const url = `${BASE_URL}${isNotMy ?? "my/"}${endpt}`;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    appendLocalStorageValuesToHeaders(headers);

    const reqInit = {
      method: "DELETE",
      headers,
    };

    const resp = await fetch(url, reqInit);
    const resBody: V = await resp.json();

    return resBody;
  }

  export async function getAdmin<V>(endpt: string): Promise<V> {
    const url = `${BASE_ADMIN_URL}${endpt}`;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const reqInit = {
      method: "GET",
      headers,
    };

    const resp = await fetch(url, reqInit);
    const resBody: V = await resp.json();

    return resBody;
  }

  export async function postAdmin<V>(endpt: string, body: Object): Promise<V> {
    const url = `${BASE_ADMIN_URL}${endpt}`;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const reqInit = {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    };

    const resp = await fetch(url, reqInit);
    const resBody: V = await resp.json();

    return resBody;
  }

  export async function deleteAdmin<V>(endpt: string): Promise<V> {
    const url = `${BASE_ADMIN_URL}${endpt}`;

    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const reqInit = {
      method: "DELETE",
      headers,
    };

    const resp = await fetch(url, reqInit);
    const resBody: V = await resp.json();

    return resBody;
  }
}

import SaturnLogoNewWhite from "@/public/images/SaturnLogoNew-White.png";
import StaticPillarChart from "@/public/images/static-pillar-chart.jpg";
import CrossIcon from "@/public/images/icons/cross-icon.png";
export const BuyDialog: React.FC<{
  title: string;
  description: string;
  offerDescription: string;
  onAcept: () => void;
  onCancel: () => void;
}> = ({ title, description, offerDescription, onAcept, onCancel }) => {
  return (
    <div className="fixed inset-0  flex items-center justify-center z-[99] bg-[#00000033] confirm-dialog overflow-y-auto">
      <div className="relative px-4 md:flex md:items-center md:justify-center">
        <div className="opacity-25 absolute z-10 inset-0  "></div>
        <div className="bg-[#222222] rounded-lg w-[70%] bottom-0 z-[50] h-screen">
          <div className="flex m-3 justify-end cursor-pointer ">
            <img
              src={CrossIcon}
              alt="cross-icon"
              className="w-[21px] h-[21px]"
              onClick={onCancel}
            />
          </div>
          <div className="flex flex-col items-center justify-center  h-[150px]">
            <img
              src={SaturnLogoNewWhite}
              alt="saturn-logo-new-white"
              className="w-[176px]"
            />
            <div className="text-center text-[24px] font-gilroy font-[600]">
              Saturn Leadership™ 360 Report
            </div>
          </div>
          <div className="flex bg-[#FAF6F0] text-[#222222] p-[40px]">
            <div>
              <p className="font-semibold font-inter text-[21px] pb-4">
                {title}
              </p>
              <p className="font-inter text-[16px] pb-4">{description}</p>
              <p className="font-inter text-[16px] text-[#012F87]">
                {offerDescription}
              </p>
            </div>
            <div>
              <img
                src={StaticPillarChart}
                alt="static-pillar-chart"
                className="p-4"
              />
            </div>
          </div>
          <div className="text-center bg-[#FAF6F0]">
            <button
              id="confirm-delete-btn"
              className="w-full md:w-auto px-[20px] py-[16px] mb-10  bg-[#E3B735] text-[#222222] rounded-lg font-gilroy font-semibold text-[18px]"
              onClick={onAcept}
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

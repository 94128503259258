import { Font, Image, View } from "@react-pdf/renderer";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import gilroyBold from "@src/fonts/Gilroy-Bold.ttf";
import gilroy from "@src/fonts/Gilroy-Regular.ttf";
import gilroySemiBold from "@src/fonts/Gilroy-SemiBold.ttf";
import React, { useEffect, useState } from "react";
import { ReactToImage } from "@/src/utils/ReactToImage";
import { Sli360ReportTypes } from "@/src/types/360report.types";
import { AveragesVotesChart } from "./averages-votes-chart";
Font.register({
  family: "Gilroy",
  src: gilroy,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Semi-Bold",
  src: gilroySemiBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Bold",
  src: gilroyBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

export type AveragesVotesChartPdfProps = {
  data: Sli360ReportTypes.PillarSumarryPage;
  width?: string;
  height?: string;
};

export const AveragesVotesChartPdf: React.FC<AveragesVotesChartPdfProps> = ({
  data,
  width,
  height,
}) => {
  const [image, setImageData] = useState<any>();
  useEffect(() => {
    ReactToImage(
      <AveragesVotesChart pillarSummaryPage={data} />,
      setImageData,
      "1600px",
      "1600px"
    );
  }, []);

  useEffect(() => {
    console.log(image);
  }, [image]);
  if (!image) {
    return <></>;
  } else
    return (
      <View
        style={{
          width: width ? width : "500px",
          height: height ? height : "1000px",
          alignItems: "center",
        }}
      >
        {image && <Image source={image} />}
      </View>
    );
};

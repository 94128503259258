import { LStorage } from "@/src/lstorage";
import { O } from "@/src/prelude";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NavIntro: React.FC = () => {
  const nav = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const jwt = LStorage.getO("jwt");
    if (O.isSome(jwt)) setIsLoaded(true);
    else {
      setIsLoaded(false);
    }
  }, []);

  return (
    <div className="flex h-[90px] w-full bg-[#222222] text-[#C8B98C] p-5 justify-between font-gilroy z-[99]">
      <div className="w-[300] self-center">
        <img
          src="/images/SaturnLogoNew-White.png"
          alt="saturn-logo"
          className="w-[176px] cursor-pointer"
          onClick={() => nav("/")}
        />
      </div>

      {isLoaded ? (
        <span
          className=" self-center font-weight-600 text-[18px] leading-24 tracking-[-1%] text-right cursor-pointer"
          onClick={() => {
            LStorage.removeJwt();
            nav("/");
          }}
        >
          Sign Out
        </span>
      ) : (
        <span className=" self-center font-weight-600 text-16 leading-24 tracking-[-1%] text-right">
          THE SATURN LEADERSHIP™ INDEX SELF ASSESSMENT
        </span>
      )}
    </div>
  );
};

import { ReactToImage } from "@/src/utils/ReactToImage";
import { Font, Image, Text, View } from "@react-pdf/renderer";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import gilroyBold from "@src/fonts/Gilroy-Bold.ttf";
import gilroy from "@src/fonts/Gilroy-Regular.ttf";
import gilroySemiBold from "@src/fonts/Gilroy-SemiBold.ttf";
import React, { useEffect, useState } from "react";
import { PieChartData, SaturnPieChart } from "./saturn-pie-chart";

Font.register({
  family: "Gilroy",
  src: gilroy,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Semi-Bold",
  src: gilroySemiBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Bold",
  src: gilroyBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

export type responsesTypeAmount = {
  type: string;
  amount: number;
};

export type SaturnPieChartPdfProps = {
  title: string | null;
  data: PieChartData[];
  responsesTypeAmounts?: responsesTypeAmount[];
  showDataTitles?: boolean;
  width?: string;
  height?: string;
};

export const SaturnPieChartPdf: React.FC<SaturnPieChartPdfProps> = ({
  title,
  data,
  showDataTitles = true,
  width,
  height,
  responsesTypeAmounts,
}) => {
  const [preSortedData] = useState<PieChartData[]>(data);
  const [sortedData] = useState<PieChartData[]>(
    preSortedData.sort((a, b) => b.value - a.value)
  );
  const [image, setImageData] = useState<any>();
  useEffect(() => {
    ReactToImage(
      <SaturnPieChart data={sortedData} withoutFont={true} />,
      setImageData
    );
  }, []);

  const totalResponses = (responses: responsesTypeAmount[]) => {
    let totalResponsesAmount = 0;
    responses.forEach((response) => {
      totalResponsesAmount += response.amount;
    });

    return totalResponsesAmount;
  };

  return (
    <View
      style={{
        width: width ? width : "140px",
        height: height ? height : "140px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          backgroundColor: "white",
          fontFamily: "Inter",
          fontWeight: "semibold",
          fontSize: "12px",
          marginBottom: "2px",
          color: "#222222",
        }}
      >
        {title}
      </Text>

      <View
        style={{
          display: "flex",
          flexDirection: responsesTypeAmounts ? "row" : "column",
          justifyContent: "center",
        }}
      >
        {responsesTypeAmounts !== undefined && (
          <View
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter",
                fontWeight: "semibold",
                fontSize: "8px",
                marginBottom: "10px",
              }}
            >
              TOTAL RESPONSES: {totalResponses(responsesTypeAmounts)}
            </Text>
            {responsesTypeAmounts.map((response, index) => {
              return (
                <Text
                  key={index}
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "semibold",
                    fontSize: "8px",
                    color: "#666666",
                    marginBottom: "2px",
                  }}
                >
                  {response.type}: {response.amount}
                </Text>
              );
            })}
          </View>
        )}

        {image && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={image}
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </View>
        )}
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "20px",
          }}
        >
          {showDataTitles && (
            <View
              style={{
                display: "flex",
                flexDirection: responsesTypeAmounts ? "column" : "row",
                gap: "2px",
              }}
            >
              {data.map((item, index) => (
                <View
                  key={index + item.title}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "2px 5px 2px 5px",
                  }}
                >
                  <View
                    style={{
                      backgroundColor: item.color,
                      width: "13px",
                      height: "13px",
                      paddingTop: "3px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></View>
                  <View
                    style={{
                      height: "13px",
                      paddingTop: "3px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter",
                        fontSize: "8px",
                        color: "#222222",
                        paddingLeft: "2px",
                        alignSelf: "center",
                      }}
                    >
                      {item.title}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

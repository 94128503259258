import { Backend } from "@/src/backend/Backend";
import { FullReportPdf } from "@/src/components/report/full-report-pdf";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { O, RD } from "@/src/prelude";
import { ReportTypes } from "@/src/types/report.types";
import { PDFViewer } from "@react-pdf/renderer";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const AnonReviewSelfReportPage: React.FC = () => {
  const url = useLocation();
  const queryParams = new URLSearchParams(url.search);
  const mbTokenParam = queryParams.get("jwt");
  const [myToken, setMyToken] = useState<string | null>(null);
  useEffect(() => {
    if (mbTokenParam) {
      setMyToken(mbTokenParam);
    }
  }, [mbTokenParam]);

  const [rdReport, setRdReport] = useState<
    RD.RemoteData<any, { report: ReportTypes.SliReport }>
  >(RD.initial);

  useEffect(() => {
    if (myToken) {
      setRdReport(RD.pending);
      Backend.get<{ report: ReportTypes.SliReport }>(
        "anon/review-self-assessment",
        undefined,
        myToken
      ).then((res) => {
        console.log("RES! ", res);
        setRdReport(RD.success(res));
      });
    }
  }, [myToken]);

  if (!myToken) {
    return <></>;
  }

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      ({ report }) => {
        return (
          <div className="w-screen h-screen flex flex-col bg-[#FAF6F0]">
            <PDFViewer
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <FullReportPdf report={report} />
            </PDFViewer>
          </div>
        );
      }
    )
  );
};

import { Backend } from "@src/backend/Backend";
import { O, RD } from "@src/prelude";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

type UsersResponse = {
  id: number;
  email: string;
  assessmentState:
    | { _tag: "incomplete" }
    | { _tag: "completed"; submittedAt: string };
};

export const UsersPage: React.FC = () => {
  const [rdReports, setRdReports] = React.useState<
    RD.RemoteData<any, UsersResponse[]>
  >(RD.initial);

  useEffect(() => {
    Backend.getAdmin<UsersResponse[]>("users").then((r) => {
      setRdReports(RD.success(r));
    });
  }, []);

  return (
    <div className="flex flex-col p-8">
      <h1 className="font-bold text-4xl mb-8">Users</h1>
      {pipe(
        rdReports,
        RD.toOption,
        O.fold(
          () => <div>Loading...</div>,
          (users) => <UsersTable users={users} />
        )
      )}
    </div>
  );
};

const InvitePeerForm: React.FC<{ userId: number }> = ({ userId }) => {
  const { register, handleSubmit } = useForm<{ peerEmail: string }>();
  const [rdResult, setRdResult] = useState<RD.RemoteData<any, any>>(RD.initial);

  const onSubmit = (data: { peerEmail: string }) => {
    setRdResult(RD.pending);
    Backend.postAdmin(`users/${userId}/send-peer-invite-email`, data)
      .then((r) => {
        console.log("RESULT OF START PEER ASSESSMENT! ", r);
        setRdResult(RD.success(r));
      })
      .catch((e) => {
        console.log("ERROR OF START PEER ASSESSMENT! ", e);
        setRdResult(RD.failure(e));
      });
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <label className="text-xl font-medium text-slate-600">Peer Email</label>
      <input
        className="border border-slate-300 rounded-md p-2"
        type="email"
        {...register("peerEmail", { required: true })}
      />
      <input
        type="submit"
        className="bg-slate-600 text-white rounded-md p-2"
        value="Send Email to start peer assessment"
      />
      {pipe(
        rdResult,
        RD.fold(
          () => <div></div>,
          () => <div>Processing...</div>,
          (e) => <div>{`Error ${JSON.stringify(e)}`}</div>,
          (s) => <div>{JSON.stringify(s)}</div>
        )
      )}
    </form>
  );
};

const UsersTable: React.FC<{ users: UsersResponse[] }> = ({ users }) => {
  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th>Email</th>
          <th>Invite Peer for Assessment</th>
          <th>Assessment State</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, idx) => (
          <tr className="border" key={idx}>
            <td className="border p-4">{user.email}</td>
            <td className="px-4 border-4">
              <InvitePeerForm userId={user.id} />
            </td>
            <td className="border p-4">
              <Link
                className="cursor-pointer"
                to={`/admin/dashboard/users/${user.id}/report-summary`}
              >
                <span
                  style={{
                    color:
                      user.assessmentState._tag === "completed"
                        ? "green"
                        : "brown",
                  }}
                >
                  {user.assessmentState._tag}
                  {user.assessmentState._tag === "completed" && (
                    <span>{` (${user.assessmentState.submittedAt})`}</span>
                  )}
                </span>
              </Link>
            </td>
            {/* <td className="p-4">
              <button
                className="border p-4 bg-red-200 rounded-md"
                onClick={() => {
                  Backend.postAdmin(`users/${user.id}/delete`, {}).then((r) => {
                    console.log("RESULT OF DELETE! ", r);
                    window.location.reload();
                  });
                }}
              >
                Delete User
              </button>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// const UserReportsList: React.FC<{ reports: UserReportMeta[] }> = ({ reports }) => {
//   return (
//     <div className="flex flex-wrap gap-8">
//       {reports.map((report, idx) =>
//         <div key={idx} className="">
//           <UserReportTile report={report} />
//         </div>
//       )}
//     </div>
//   )
// }

// const UserReportTile: React.FC<{ report: UserReportMeta }> = ({ report }) => {
//   return (
//     <Link to={`/admin/dashboard/users/${report.user.id}/report-summary`}>
//       <div className="bg-white rounded-3xl py-8 pl-8 pr-16 cursor-pointer flex flex-col gap-4">
//         <h4 className="font-bold">{report.user.email}</h4>
//         <div className="flex flex-col">
//           <div>Submitted: </div>
//           <div>
//             {`${report.submittedAt}`}
//           </div>
//         </div>
//       </div>
//     </Link>
//   )
// }

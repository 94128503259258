import React from "react";
import { RotatingLines } from "react-loader-spinner";

export const LoadingSpinner: React.FC<{ dim: number }> = ({ dim }) => {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width={`${dim}`}
      visible={true}
    />
  );
};

export const FullContainerLoadingSpinner: React.FC = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <LoadingSpinner dim={45} />
    </div>
  );
};

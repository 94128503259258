import { Backend } from "@/src/backend/Backend";
import { MyPeerAnswersPdf } from "@/src/components/report/my-peer-answers-pdf";
import { O, RD } from "@/src/prelude";
import { ReportTypes } from "@/src/types/report.types";
import { PDFViewer } from "@react-pdf/renderer";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";

export const PeerOnboardMyAnswersReportPdfPage: React.FC = () => {
  const [rdReport, setRdReport] = React.useState<
    RD.RemoteData<any, ReportTypes.SliReport>
  >(RD.initial);

  useEffect(() => {
    setRdReport(RD.pending);
    Backend.get<ReportTypes.SliReport>(
      `/my-answers-report`,
      "peer-onboard"
    ).then((res) => {
      setRdReport(RD.success(res));
    });
  }, []);

  return pipe(
    rdReport,
    RD.toOption,
    O.fold(
      () => <div>Loading...</div>,
      (report) => (
        <div className="w-screen h-screen">
          <PDFViewer
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <MyPeerAnswersPdf report={report} />
          </PDFViewer>
        </div>
      )
    )
  );
};

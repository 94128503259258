import { Backend } from "@/src/backend/Backend";
import { O, RD } from "@/src/prelude";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { SampleSli360ReportContext } from "./sample-sli-360.context";
import { Sli360ReportTypes } from "@/src/types/360report.types";

export const SampleSli360Layout: React.FC<Record<string, never>> = () => {
  const [rdResult, setRdResult] = useState<
    RD.RemoteData<any, Sli360ReportTypes.FullReport>
  >(RD.initial);

  useEffect(() => {
    setRdResult(RD.pending);
    Backend.get<{ fake360Report: Sli360ReportTypes.FullReport }>(
      "sample-sli-360"
    ).then((r) => {
      setRdResult(RD.success(r.fake360Report));
    });
  }, []);

  return pipe(
    rdResult,
    RD.toOption,
    O.fold(
      () => <></>,
      (r) => {
        return (
          <SampleSli360ReportContext.Provider value={r}>
            <div className="w-screen h-screen flex">
              <div className="basis-1/8 grow-0 shrink-0 min-h-screen bg-gray-100">
                <ul className="flex flex-col gap-4 p-4">
                  <li>
                    <Link to="pillar-summary-page">Pillar Summary Page</Link>
                  </li>
                  <li>
                    <Link to="leadership-summary-page">
                      Leadership Summary Page
                    </Link>
                  </li>
                  <li>
                    <Link to="strengths-improvement-page">
                      Strengths & Improvement Opportunities
                    </Link>
                  </li>
                  <li>
                    <Link to="comments-page">Comments</Link>
                  </li>
                </ul>
              </div>
              <div className="flex-1 min-h-screen p-8 bg-[#FFFFFF]">
                <Outlet />
              </div>
            </div>
          </SampleSli360ReportContext.Provider>
        );
      }
    )
  );
};

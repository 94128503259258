import { Sli360ReportTypes } from "@/src/types/360report.types";
import React from "react";

export const SampleSli360ReportContext =
  React.createContext<Sli360ReportTypes.FullReport | null>(null);

export function useSampleSli360Report() {
  const ctx = React.useContext(SampleSli360ReportContext);
  console.log(ctx);
  if (!ctx) {
    throw new Error(
      `useSampleSli360Report must be used within a SampleSli360ReportProvider`
    );
  }
  return ctx;
}

import {
  SurveyContainer,
  SurveyOneToFiveQuestion,
} from "@/src/components/survey/survey.components";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { Backend } from "../../backend/Backend";
import { BackButton, PrimaryButton } from "../../components/buttons";
import { RD } from "../../prelude";

export type QuestionWithAnswer = {
  id: string;
  question: string;
  pillar_name: string;
  answer: number | null;
};

type SimplePillar = { id: string; number: number };

type MostImportantPillarItem = { comment: string };

export type GetSelfAssessmentResponse = {
  qas: QuestionWithAnswer[];
  nextPillar: SimplePillar | null;
  mbMostImportantPillarItem: MostImportantPillarItem | null;
};

export const SelfOnboardSelfAssessmentPage: React.FC = () => {
  const [rdSelfAssessment, setRdSelfAssessment] = React.useState<
    RD.RemoteData<any, GetSelfAssessmentResponse>
  >(RD.initial);
  const pillarNumberParam = useParams().pillarNumber!;
  const [_, setLastSavedAt] = React.useState<Date | null>(null);

  const pillarName = () => {
    switch (pillarNumberParam) {
      case "1":
        return "Inspire with a Clear Vision & Roadmap";
      case "2":
        return "Listen with Openness & Curiosity";
      case "3":
        return "Speak with Transparency & Authenticity";
      case "4":
        return "Facilitate Healthy Debates & Meetings";
      case "5":
        return "Make Decisions Inclusively & Effectively";
      case "6":
        return "Delegate Effectively & Creates Accountable Teams";
      case "7":
        return "Develop People & Teams";
      default:
        return "Unknown";
    }
  };

  const toBack = () => {
    if (pillarNumberParam !== "1") {
      return `/self-onboard/self-assessment-by-pillar/${
        Number.parseInt(pillarNumberParam) - 1
      }`;
    }

    return "/self-onboard/self-assessment-start";
  };

  console.log("PILLAR NUMBER PARAM: ", rdSelfAssessment);

  React.useEffect(() => {
    setRdSelfAssessment(RD.pending);
    Backend.get<GetSelfAssessmentResponse>(
      `/self-assessment?pillarNumber=${pillarNumberParam}`,
      "self-onboard"
    )
      .then((r) => {
        console.log("GOT SELF ASSESSMENT: ", r);
        setRdSelfAssessment(RD.success(r));
      })
      .catch((e) => {
        console.error(e);
      });
  }, [pillarNumberParam]);

  return pipe(
    rdSelfAssessment,
    RD.fold(
      () => <></>,
      () => <FullContainerLoadingSpinner />,
      () => <></>,
      (r) => (
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <BackButton navToBack={toBack()} />
            <div className="flex flex-col gap-10 self-center items-center w-full pt-2 bg-[#FAF6F0]">
              <div className="text-center">
                <h3 className="text-lg font-gilroy font-semibold text-[#727272]">
                  PILLAR {pillarNumberParam}
                </h3>
                <h1
                  className="m-4 text-2xl font-normal"
                  style={{ fontFamily: "Arial" }}
                >
                  {pillarName()}
                </h1>
              </div>

              <AllQuestionsForm
                questions={r.qas}
                pillarNumber={parseInt(pillarNumberParam)}
                nextPillarNumber={r.nextPillar?.number}
                mbMostImportantPillarItem={r.mbMostImportantPillarItem}
                onSaved={() => {
                  setLastSavedAt(new Date());
                }}
              />
            </div>
          </div>
        </div>
      )
    )
  );
};

const AllQuestionsForm: React.FC<{
  questions: QuestionWithAnswer[];
  onSaved: () => void;
  pillarNumber: number;
  nextPillarNumber?: number | null;
  mbMostImportantPillarItem?: MostImportantPillarItem | null;
}> = ({
  questions,
  onSaved,
  pillarNumber,
  nextPillarNumber,
  mbMostImportantPillarItem,
}) => {
  const nav = useNavigate();
  const [mostImportantNote, setMostImportantNote] = React.useState<string>(
    mbMostImportantPillarItem ? mbMostImportantPillarItem.comment : ""
  );
  const [selectedAnswers, setSelectedAnswers] = React.useState<{
    [key: string]: number | null;
  }>(
    questions.reduce((acc, { id, answer }) => {
      acc[id] = answer;
      return acc;
    }, {} as { [key: string]: number | null })
  );

  const fillAll = () => {
    const updatedAnswers = Object.fromEntries(
      Object.keys(selectedAnswers).map((key) => [
        key,
        Math.ceil(Math.random() * 5),
      ])
    );
    setSelectedAnswers(updatedAnswers);
  };

  const numSelectedAnswers = React.useMemo(
    () => Object.values(selectedAnswers).filter((v) => v !== null).length,
    [selectedAnswers]
  );

  useEffect(() => {
    console.log("SELECTED ANSWERE: ", selectedAnswers);
    Backend.post(
      `/self-assessment-actions/save`,
      selectedAnswers,
      "self-onboard"
    ).then((_) => {
      onSaved();
    });
  }, [selectedAnswers]);

  const formCompleteEnough = questions.length - numSelectedAnswers <= 10;

  return (
    <div className="flex flex-col gap-8 px-8 items-center w-full">
      <SurveyContainer>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row"></div>
          <div className="flex items-center px-4">
            <div className="flex flex-row gap-16 text-sm">
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Strongly Disagree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Disagree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Neutral
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Agree
              </div>
              <div className="flex items-center justify-center w-5 text-[#7D7D7D]">
                Strongly Agree
              </div>
            </div>
          </div>
        </div>
        {questions.map(({ question, id }) => {
          return (
            <div className="border-b border-[#cdc8bb]">
              <SurveyOneToFiveQuestion
                question={question}
                key={question}
                radioProps={{
                  // withNa: true,
                  selected:
                    selectedAnswers[id] !== null
                      ? selectedAnswers[id]!.toString()
                      : null,
                  onSelected: (n) => {
                    setSelectedAnswers((r) => {
                      console.log("N! ", n);
                      return {
                        ...r,
                        [id]: n ? parseInt(n) : null,
                      };
                    });
                  },
                  name: id,
                  displayType: "Agreeableness",
                }}
              />
            </div>
          );
        })}
        <div>
          <input
            type="text"
            placeholder={`Anything else about this pillar that you consider important`}
            className="border-b border-[#C8B98C] font-gilroy text-[18px] mt-10 bg-[#FAF6F0] text-start px-4 py-2 w-full"
            value={mostImportantNote}
            onChange={(e) => {
              setMostImportantNote(e.target.value);
            }}
          />
        </div>
      </SurveyContainer>
      <div className="self-center flex flex-col items-center pb-12">
        {numSelectedAnswers !== questions.length && (
          <span className="text-red-500 text-center">
            {`${questions.length - numSelectedAnswers} unanswered`}
          </span>
        )}
        <PrimaryButton
          onClick={() => {
            console.log("submit", selectedAnswers);
            const body = {
              selectedAnswers,
              mostImportantNote,
              pillarNumber,
            };

            if (nextPillarNumber) {
              Backend.post(
                `/self-assessment-actions/save-most-important-pillar-item`,
                body,
                "self-onboard"
              ).then((r) => {
                console.log("SUBMITTED SELF ASSESSMENT: ", r);
              });
              nav(
                `/self-onboard/self-assessment-by-pillar/${nextPillarNumber}`
              );
            } else {
              Backend.post(
                `/self-assessment-actions/save-most-important-pillar-item`,
                body,
                "self-onboard"
              ).then((r) => {
                console.log("SUBMITTED SELF ASSESSMENT: ", r);
              });
              Backend.post(
                `/self-assessment-actions/submission`,
                {},
                "self-onboard"
              ).then((r) => {
                nav(`/self-onboard/level-of-weaknesses`);
                console.log("SUBMITTED SELF ASSESSMENT: ", r);
              });
            }
          }}
          disabled={!formCompleteEnough}
          style={{
            backgroundColor: "#222222",
            color: "#FAF7F2",
            padding: "16px 20px 16px 20px",
            opacity: formCompleteEnough ? "" : "30%",
          }}
        >
          <div className="flex items-center">
            Next Section
            <HiChevronRight />
          </div>
        </PrimaryButton>
      </div>
      <button
        className="flex self-end w-[40px] h-[40px]"
        onClick={fillAll}
      ></button>
    </div>
  );
};

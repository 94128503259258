import { Font, Text, View } from "@react-pdf/renderer";
import React from "react";
import interBold from "@src/fonts/Inter-Bold.ttf";
import interSemibold from "@src/fonts/Inter-SemiBold.ttf";
import inter from "@src/fonts/Inter-Regular.ttf";
import gilroyBold from "@src/fonts/Gilroy-Bold.ttf";
import gilroy from "@src/fonts/Gilroy-Regular.ttf";
import gilroySemiBold from "@src/fonts/Gilroy-SemiBold.ttf";
Font.register({
  family: "Gilroy",
  src: gilroy,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Semi-Bold",
  src: gilroySemiBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Gilroy-Bold",
  src: gilroyBold,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Inter",
  src: interBold,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Inter",
  src: interSemibold,
  fontStyle: "normal",
  fontWeight: "semibold",
});
Font.register({
  family: "Inter",
  src: inter,
  fontStyle: "normal",
  fontWeight: "normal",
});

export type ScoreOnReportPdfProps = {
  title: string;
  score: number;
};

const ScoreOnReportPdf: React.FC<ScoreOnReportPdfProps> = ({
  title,
  score,
}) => {
  const ScoreColor = () => {
    switch (true) {
      case score > 4:
        return "#83BC75";
      case score <= 4 && score > 3:
        return "#D2E7CE";
      case score <= 3 && score > 2:
        return "#FEB165";
      case score <= 2 && score > 1:
        return "#FF8E25";
      case score < 1:
        return "#E64925";
    }
  };
  const scales = [
    { number: 5, title: "Strong", color: "#83BC75" },
    { number: 4, title: "Good", color: "#D2E7CE" },
    { number: 3, title: "Functional", color: "#FEB165" },
    { number: 2, title: "Needs Improvement", color: "#FF8E25" },
    { number: 1, title: "Major Weakness", color: "#E64925" },
  ];
  return (
    <View
      style={{
        gap: "15px",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontFamily: "Inter",
          fontWeight: "semibold",
          fontSize: "12px",
          textAlign: "center",
          color: "#222222",
        }}
      >
        {title}
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            backgroundColor: ScoreColor(),
            width: "100px",
            height: "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter",
              fontSize: "25px",
              fontWeight: "bold",
              color: "#222222",
              textAlign: "center",
            }}
          >
            {score}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px 0px 5px 20px",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter",
              fontWeight: "semibold",
              fontSize: "10px",
              textAlign: "center",
              color: "#222222",
            }}
          >
            Scale
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",

              marginTop: "5px",
            }}
          >
            {scales.map((scale, index) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                key={index}
              >
                <View
                  style={{
                    backgroundColor: scale.color,
                    width: "8px",
                    height: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
                <View
                  style={{
                    display: "flex",
                    height: "13px",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "normal",
                      fontSize: "8px",
                      color: "#222222",
                      paddingLeft: "2px",
                      alignSelf: "center",
                    }}
                  >
                    {scale.number} - {scale.title}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ScoreOnReportPdf;

import { ReportTypes, SliReport } from "@src/types/report.types";
import okeyIcon from "/images/okey-yellow.png";
import ratingGoodIcon from "/images/rating-good.png";

export const FriendlyScoreNameMap: {
  [key: number]: ReportTypes.FriendlyScoreName;
} = {
  1: "Major Weakness",
  2: "Needs Improvement",
  3: "Functional",
  4: "Good",
  5: "Strength",
};

export function scoreToFriendlyName(
  score: number
): ReportTypes.FriendlyScoreName {
  return FriendlyScoreNameMap[score as keyof typeof FriendlyScoreNameMap];
}

export function friendlyNameToHexColor(
  name: ReportTypes.FriendlyScoreName
): string {
  switch (name) {
    case "Major Weakness":
      return "#EF4444";
    case "Needs Improvement":
      return "#FF8E25";
    case "Functional":
      return "#FEB165";
    case "Good":
      return "#D2E7CE";
    case "Strength":
      return "#83BC75";
  }
}

export function formatRating(rating: number): string {
  // If the ratings first 2 decimal places are 0, then just show that number
  // otherwise show the first decimal place

  const ratingString = rating.toString();
  const decimalIndex = ratingString.indexOf(".");

  if (decimalIndex === -1) {
    return rating.toString();
  }
  const firstDecimal = ratingString[decimalIndex + 1];

  console.log("RAGIN! ", rating, firstDecimal);

  if (firstDecimal && firstDecimal === "0") {
    return rating.toFixed(0);
  }

  return rating.toFixed(1);
}

export function pillarRatingExplanation(rating: number) {
  switch (rating) {
    case 1:
      return "This is a major weakness. You need significant improvement in this area.";
    case 2:
      return "This is a weakness. You need improvement in this area.";
    case 3:
      return "You are functional in this area, but a little improvement is recommended.";
    case 4:
      return "You are already good in this area and some improvement would make it into a strength.";
    case 5:
      return "You are very good in this area. Consider this a core strength. Remember, it may not be as easy for other people as it is for you.";
    default:
      return "";
  }
}

export function pillarRatingIcon(rating: number) {
  switch (rating) {
    case 3:
      return okeyIcon;
    case 4:
      return ratingGoodIcon;
    case 5:
      return ratingGoodIcon;
    default:
      return okeyIcon;
  }
}

export const StrengthsOrWeaknessRatings = (report: SliReport) => {
  const tempResult: {
    majorWakness: number;
    needsImprovement: number;
    functional: number;
    good: number;
    strong: number;
  }[] = [];

  let totalItems = 0;

  report.sa.pillarBreakdown.forEach((item) => {
    totalItems += item.ratings.length;
  });

  report.sa.pillarBreakdown.forEach((pillar) => {
    const ratingsCounts = [0, 0, 0, 0, 0];

    pillar.ratings.forEach((rating) => {
      ratingsCounts[rating.rating - 1] += 1;
    });
    const allAdded = ratingsCounts.reduce((total, count) => total + count, 0);
    const averages = ratingsCounts.map((count) => {
      const countPonderated = (pillar.ratings.length * 100) / totalItems;
      return (count * countPonderated) / allAdded;
    });

    tempResult.push({
      majorWakness: averages[0],
      needsImprovement: averages[1],
      functional: averages[2],
      good: averages[3],
      strong: averages[4],
    });
  });

  return tempResult;
};

export const PillarAvgTotalRatings = (
  pillarBrakedowns: ReportTypes.Sa.PillarBreakdown[]
): number => {
  console.log("PILLAR BRAKE DOWNS: ", pillarBrakedowns);
  const RatingsAvg = pillarBrakedowns
    .filter((pillar) => pillar.avg !== "0" && pillar.avg !== "NaN.0")
    .map((pillarBrakedown) => parseFloat(pillarBrakedown.avg));

  const totalRatingsAvg = RatingsAvg.reduce(
    (total, numero) => total + numero,
    0
  );
  return parseFloat((totalRatingsAvg / RatingsAvg.length).toFixed(1));
};

export const PillarTypesPercentage = (
  leadershipSummary: ReportTypes.Sa.LeadershipSummary
): number[] => {
  const totalStrengths = leadershipSummary.strengths;
  const totalFunctional = leadershipSummary.functional;
  const totalWeaknesses = leadershipSummary.weaknesses;

  const total = totalStrengths + totalFunctional + totalWeaknesses;

  const percentageStrengths = (totalStrengths * 100) / total;
  const percentageFunctional = (totalFunctional * 100) / total;
  const percentageWeaknesses = (totalWeaknesses * 100) / total;
  return [
    parseFloat(percentageStrengths.toFixed(0)),
    parseFloat(percentageFunctional.toFixed(0)),
    parseFloat(percentageWeaknesses.toFixed(0)),
  ];
};

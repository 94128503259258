export const ProgressBar: React.FC<{ progressPct: number }> = ({
  progressPct,
}) => {
  return (
    <div className="w-full h-[7px] bg-[#7D7D7D]">
      <div
        className="h-full bg-[#C8B98C]"
        style={{ width: `${progressPct}%` }}
      />
    </div>
  );
};

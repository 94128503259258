import { BackButton, PrimaryButton } from "@/src/components/buttons";
import NumberQuestion from "@/src/components/questions/number-question";
import OptionQuestion from "@/src/components/questions/options-question";
import QuestionLabel from "@/src/components/questions/question-label";
import YesNoQuestion from "@/src/components/questions/yes-no-question";
import { SurveyContainer } from "@/src/components/survey/survey.components";
import { ErrorTracking } from "@/src/error-tracking";
import { FullContainerLoadingSpinner } from "@/src/loading";
import { menuSteps } from "@/src/types/enums.types";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import { useNavigate, useParams } from "react-router-dom";
import { Backend } from "../../backend/Backend";
import { E, O, RD } from "../../prelude";

export type FinalQuestion = {
  question: {
    questionSlug: string;
    questionText: string;
    answerType: "yes-no" | "number" | "options" | "email";
  };
  progressPct: number;
  answer: "yes" | "no" | number | string | null;
};

export const SelfOnboardFinalQuestionsPage: React.FC = () => {
  const params = useParams<{ questionSlug: string }>();
  const { questionSlug } = params;
  const nav = useNavigate();
  const [rdQuestions, setRdQuestions] = React.useState<
    RD.RemoteData<any, FinalQuestion[]>
  >(RD.initial);
  const [enteredExitedState, setEnteredExitedState] =
    useState<EnteredExitedState>("initial");
  const [emails, setEmails] = React.useState<string[]>([]);

  const sendForm = (completeQuestions: FinalQuestion[]) => {
    const body = completeQuestions.map((completeQuestion) => {
      return {
        questionSlug: completeQuestion.question.questionSlug,
        answer: completeQuestion.answer
          ? completeQuestion.answer?.toString()
          : "",
      };
    });
    Backend.post<{ progressPct: number }>(
      `final-assessment/questions/answers`,
      body
    )
      .then((_) => {
        nav(`/anon/self-assessment/report-complete`);
        // console.log("GOT RESULT! ", r);
        // Backend.post(
        //   `final-assessment/submission`,
        //   JSON.parse("{}"),
        //   "rest/"
        // ).then((r) => {
        //   console.log("SUBMITTED FINAL ASSESSMENT: ", r);

        // });
      })
      .catch((e) => {
        console.log("ERROR ENTER");
        console.error(e);
      });
  };

  useEffect(() => {
    console.log("SET ENTERED EXITED STATE: ", enteredExitedState);
  }, [enteredExitedState]);

  useEffect(() => {
    setEnteredExitedState("initial");
    const r = setTimeout(() => {
      setEnteredExitedState("entered");
    }, 300);

    return () => {
      clearTimeout(r);
    };
  }, [questionSlug]);

  useEffect(() => {
    setRdQuestions(RD.pending);
    Backend.getE<FinalQuestion[]>(`final-assessment/questions`).then((r) => {
      console.log("GOT PROGRESS: ", r);
      if (E.isRight(r)) {
        setRdQuestions(RD.success(r.right));
      } else {
        setRdQuestions(RD.failure(r.left));
        ErrorTracking.captureResponseError(r.left);
      }
    });
  }, [questionSlug]);

  return pipe(
    rdQuestions,
    RD.toOption,
    O.fold(
      () => <FullContainerLoadingSpinner />,
      (completeQuestions) => (
        <div className="w-full flex flex-col">
          <BackButton navToBack="/self-onboard/level-of-weaknesses" />
          <div className="flex flex-col items-center">
            <div className="flex flex-col self-stretch">
              <div
                className={`
            flex-1 flex flex-col self-center items-start px-12  w-[80%]
            `}
              >
                <h1 className="self-center text-4xl font-bodonixt text-black m-14 text-center">
                  {menuSteps.final}
                </h1>
                <div className=" mb-10 w-full ">
                  <SurveyContainer
                    explanation={
                      <span className="font-gilroy font-bold self-center">
                        {"The following questions are completely optional"}
                      </span>
                    }
                  >
                    {completeQuestions.map((completeQuestion, index) => {
                      return (
                        <div
                          className="rounded-2xl flex flex-col pl-14 pr-14 bg-[#FAF6F0] mb-[60px]"
                          key={index}
                        >
                          {completeQuestion.question.answerType ===
                            "yes-no" && (
                            <YesNoQuestion
                              question={completeQuestion.question.questionText}
                              answer={
                                completeQuestion.answer as "yes" | "no" | null
                              }
                              setAnswer={(
                                newAnswer: number | "yes" | "no" | null
                              ) => {
                                const updatedRdQuestions: FinalQuestion[] =
                                  completeQuestions;
                                updatedRdQuestions[index].answer = newAnswer;
                                setRdQuestions(RD.success(updatedRdQuestions));
                              }}
                            />
                          )}
                          {completeQuestion.question.answerType === "email" && (
                            <div className="flex flex-col gap-4">
                              <QuestionLabel
                                question={
                                  completeQuestion.question.questionText
                                }
                              />
                              <ReactMultiEmail
                                placeholder="Emails"
                                className={`flex flex-wrap border-b border-[#C8B98C] font-gilroy text-[18px] text-start px-4 py-2 w-full`}
                                emails={emails}
                                onChange={(_emails: string[]) => {
                                  const updatedRdQuestions: FinalQuestion[] =
                                    completeQuestions;
                                  updatedRdQuestions[index].answer =
                                    _emails.toString();
                                  setRdQuestions(
                                    RD.success(updatedRdQuestions)
                                  );
                                  setEmails(_emails);
                                }}
                                autoFocus={true}
                                getLabel={(email, index, removeEmail) => {
                                  return (
                                    <div
                                      data-tag
                                      key={index}
                                      className=" bg-[#E2DFD8] flex  items-center py-[5px] px-[10px] rounded m-1"
                                    >
                                      <div
                                        data-tag-item
                                        className="font-gilroy text-[#222222] font-medium"
                                      >
                                        {email}
                                      </div>
                                      <span
                                        data-tag-handle
                                        className="cursor-pointer ml-2 text-[#000000] text-2xl"
                                        onClick={() => removeEmail(index)}
                                      >
                                        ×
                                      </span>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                          )}
                          {completeQuestion.question.answerType ===
                            "number" && (
                            <NumberQuestion
                              question={completeQuestion.question.questionText}
                              answer={completeQuestion.answer as number}
                              setAnswer={(newAnswer: number) => {
                                const updatedRdQuestions: FinalQuestion[] =
                                  completeQuestions;
                                updatedRdQuestions[index].answer = newAnswer;
                                setRdQuestions(RD.success(updatedRdQuestions));
                              }}
                            />
                          )}
                          {completeQuestion.question.answerType ===
                            "options" && (
                            <OptionQuestion
                              options={[
                                "Corporation",
                                "Small Business",
                                "Startup or Entrepreneurial Venture",
                                "Other",
                              ]}
                              question={completeQuestion.question.questionText}
                              answer={completeQuestion.answer as string}
                              setAnswer={(newAnswer: string) => {
                                const updatedRdQuestions: FinalQuestion[] =
                                  completeQuestions;
                                updatedRdQuestions[index].answer = newAnswer;
                                setRdQuestions(RD.success(updatedRdQuestions));
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                    <div className="text-center mb-[60px]">
                      <PrimaryButton
                        type="submit"
                        title="Next"
                        style={{
                          backgroundColor: "#222222",
                          color: "#FAF7F2",
                          padding: "16px 20px 16px 20px",
                        }}
                        onClick={() => sendForm(completeQuestions)}
                      >
                        <div className="flex items-center">Submit answers</div>
                      </PrimaryButton>
                    </div>
                  </SurveyContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    )
  );
};

type EnteredExitedState = "initial" | "entered" | "exited";

import React from "react";
import QuestionLabel from "./question-label";

type YesNoQuestionProps = {
  question: string;
  answer: "yes" | "no" | null;
  setAnswer: (newAnswer: number | "yes" | "no" | null) => void;
};

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
  question,
  answer,
  setAnswer,
}) => {
  return (
    <div className="flex flex-col items-start gap-8 ">
      <QuestionLabel question={question} />
      <div className="flex flex-row justify-center items-center gap-8">
        <div className="flex items-center gap-1 font-gilroy text-bold">
          <label className="relative">
            <input
              type="radio"
              id="yes"
              value="yes"
              className="border w-5 h-5 flex"
              style={{
                appearance: "none",
                borderRadius: "50%",
                backgroundColor: answer === "yes" ? "#CFBB8B" : "white",
                border: answer === "yes" ? "" : "#7D7D7D solid 1.5px",
              }}
              onClick={() =>
                answer === "yes" ? setAnswer(null) : setAnswer("yes")
              }
            />
            <span
              className={`absolute inset-0 flex items-center justify-center h-[20px]`}
            >
              {answer === "yes" && (
                <img
                  src="/images/icons/check.png"
                  alt="check-icon"
                  className="h-[8.78px] w-[12.55px] top-[7.64px]"
                />
              )}
            </span>
          </label>

          <div>
            <label
              htmlFor="yes"
              className="flex font-gilroy font-semibold text-[18px] m-2"
            >
              Yes
            </label>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <label className="relative">
            <input
              type="radio"
              id="no"
              value="no"
              className="border w-5 h-5 flex"
              style={{
                appearance: "none",
                borderRadius: "50%",
                backgroundColor: answer === "no" ? "#CFBB8B" : "white",
                border: answer === "no" ? "" : "#7D7D7D solid 1.5px",
              }}
              onClick={() =>
                answer === "no" ? setAnswer(null) : setAnswer("no")
              }
            />
            <span
              className={`absolute inset-0 flex items-center justify-center h-[20px]`}
            >
              {answer === "no" && (
                <img
                  src="/images/icons/check.png"
                  alt="check-icon"
                  className="h-[8.78px] w-[12.55px] top-[7.64px]"
                />
              )}
            </span>
          </label>

          <label
            htmlFor="no"
            className="flex font-gilroy font-semibold text-[18px] m-2"
          >
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default YesNoQuestion;
